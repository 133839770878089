import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as utils from '../globals/utils';
import { PopUpService } from './pop-up.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertType } from 'sweetalert2';
import { EOHttpClient } from './eohttp-client.service';
import { models } from '../globals/model-names';

export interface ErrorOptions {
  title?:string;
  message:string;
  err?:string;
  swal?:SwalComponent;
  type?:SweetAlertType;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  /* Guides */
  guides:any[];

  /* Probably all of this can be removed and use guides array with objects instead */
  guidePages:any[];
  guideNotes:any[];
  guideTitles:any[];
  guideDesc:any[];
  guideCts:any[];

  /* Countries */
  // countries$:BehaviorSubject<any[]> = new BehaviorSubject(null);
  provinces$:BehaviorSubject<any[]> = new BehaviorSubject(null);
  industries$:BehaviorSubject<any[]> = new BehaviorSubject(null);
  rejectionReasons$:BehaviorSubject<any[]> = new BehaviorSubject(null);

  constructor(private http:HttpClient, private eoHttp:EOHttpClient, private popUpService:PopUpService) {
    this.prepareGuide();
    // this.prepareCountries();
    this.prepareIndustries();
    this.prepareRejectionReasons();
    this.prepareProvinces();
  }

  prepareRejectionReasons(){
    this.getAllRejections().subscribe((res:any)=>{
      this.rejectionReasons$.next(res.data);
    });
  }

  // prepareCountries(){
  //   this.getAllCountries().subscribe((res:any)=>{
  //     this.countries$.next(res.data);
  //   });
  // }

  prepareProvinces(){
    this.getAllProvinces().subscribe((res:any)=>{
      this.provinces$.next(res.data);
    });
  }

  prepareIndustries(){
    this.getAllIndustries().subscribe((res:any)=>{
      this.industries$.next(res.data);
    });
  }

  getIndustries(){
    return this.eoHttp.get('/api/'+models.INDUSTRY, {page: 1, pageSize: 100});
  }

  prepareGuide(){
    this.getGuide().subscribe((res:any)=>{
      var guide = res;
      this.guides = [];
      
      /* Probably all of this can be removed and use guides array with objects instead */
      this.guideTitles = [];
      this.guideDesc = [];
      this.guideCts = [];
      this.guideNotes = [];
      this.guidePages = guide.split("<div class=\"guide-page\"");
      for(var i in this.guidePages){
        //recomponemos el div inicial
        this.guidePages[i] = "<div class=\"guide-page\""+this.guidePages[i];

        var indexAuxTitle = this.guidePages[i].indexOf("<p class=\"case-title");
        var indexAuxDesc = this.guidePages[i].indexOf("<div class=\"case-description");
        var indexAuxCts = this.guidePages[i].indexOf("<table cellpadding=0 cellspacing=0 class=\"guide-table case-credits");
        var indexAuxEndCts = this.guidePages[i].lastIndexOf("<\/div>");

        var auxGuide = {
          pages : this.guidePages[i],
          notes : this.guidePages[i].substring(0,indexAuxTitle),
          title : this.prepareTitle(this.guidePages[i].substring(indexAuxTitle,indexAuxDesc)),
          desc : this.guidePages[i].substring(indexAuxDesc,indexAuxCts),
          credits : this.guidePages[i].substring(indexAuxCts,indexAuxEndCts)
        }
        this.guides.push(auxGuide);

        this.guideNotes.push(this.guidePages[i].substring(0,indexAuxTitle));
        this.guideTitles.push(this.prepareTitle(this.guidePages[i].substring(indexAuxTitle,indexAuxDesc)));
        this.guideDesc.push(this.guidePages[i].substring(indexAuxDesc,indexAuxCts));
        this.guideCts.push(this.guidePages[i].substring(indexAuxCts,indexAuxEndCts));
      }
    })
  }

  goToMaps(address){
    let searchString = `${address.streetType.name}+${address.streetName}`;
    if(address.number) searchString+=`+${address.number}`;
    if(address.postalCode) searchString+=`+${address.postalCode}`;
    if(address.location && address.location.name) searchString+=`+${address.location.name}`;
    window.open('https://google.es/maps/search/'+searchString);
  }

  prepareTitle(title){
    var aux = title.substring(title.indexOf("TÍTULO:")+7);
    aux = aux.replace("<\/p>","");
    return aux.trim();
  }

  getChannels(extraParams?){
    return this.eoHttp.get('/api/lead_channels', extraParams);
  }

  getAllProvinces(){
    return this.http.get(utils.formatUrl('/Province/getAll'));
  };

  getAllCountries(){
    return this.http.get(utils.formatUrl('/Country/getAll'));
  }

  getGuide(){
    return this.http.get('/assets/html/guia.html', {responseType: 'text'});
  };

  getAllRejections(){
    return this.http.get(utils.formatUrl('/RejectionReason/getAll'));
  };
  getAllRejections2(){
    return this.eoHttp.get('/api/lead_rejection_reasons');
  };

  getAllIndustries(){
    return this.http.get(utils.formatUrl('/Industry/getAll'));
  };

  getAllNewIndustries(){
    return this.eoHttp.get('/api/industries');
  }

  getIPCoordinates(ip){
    return this.http.get('http://ip-api.com/json/'+ip);
  }

  manageError(options:ErrorOptions){
    options = Object.assign({title:"Error", type:"error"}, options);
    if(options.err){
        console.error(JSON.stringify(options.err));
    }
    else{
        console.error(options.message);
    }
    let swalPromise = this.popUpService.swal(options.title, options.message, options.type);
    if(options.swal){
      swalPromise.then(res=>{
        options.swal.show();
      })
    }
    else{
      this.popUpService.hideLoading();
      return swalPromise;
    }
  }

  uploadImage(file,idp,type){
    let endPoint = type == 'organization' ? 'uploadOrganizationPicture' : 'uploadPartnerPicture';
    let extension = type == 'organization' ? 'png' : 'jpg';
    file.lastModifiedDate = new Date();
    file.name = 'newPicture';
    let formData:FormData = new FormData();
    formData.append('IdP',idp);
    formData.append('eotoken','eoffer');
    formData.append('picture',file,'picture.'+extension);
    return this.http.post('https://abogados.easyoffer.es/'+endPoint+'.php',formData)
  }

  sendErrorNotification(message){
    var body = utils.toHttpParams({
        Message: message,
        Icon:":warning:",
        Channel:"core-log",
        Username:"EOLOG-ADMIN2"
    });
    return this.http.post(utils.formatUrl('/Notification/slackMessage'),body)
  };

  reduceIRIs(object, reduceToIRI=true){
    let objectCopy = JSON.parse(JSON.stringify(object));
    for(let key in objectCopy){
      if(objectCopy[key] && objectCopy[key]["@id"]){
        objectCopy[key] = reduceToIRI ? objectCopy[key]["@id"] : objectCopy[key].id;
      }
      else if(Array.isArray(objectCopy[key]) && objectCopy[key].length>0){
        let resArray = [];
        for(let elem of objectCopy[key]){
          if(elem["@id"]){
            resArray.push(reduceToIRI ? elem["@id"] : elem.id);
          }
          else if(typeof elem=='string'){
            resArray.push(reduceToIRI ? elem : utils.getIdFromIRI(elem));
          }
          else if (typeof elem == 'object' && elem != null){
            resArray.push(this.reduceIRIs(elem));
          }
        }
        objectCopy[key] = resArray;
      }
    }
    return objectCopy;
  }

  populateIRIs(object, skipKeys:string[] = [], includeKeys:string[] = []){
    return new Promise((resolve1,reject1)=>{
      let promises = [];
      for(let key in object){
        if(key=='@id' || key=='@context')
          continue;
        if(skipKeys.includes(key) || (includeKeys.length>0 && !includeKeys.includes(key))){
          continue;
        }
        if(object[key] && typeof object[key]=='string' && object[key].indexOf('/api/')!=-1){
          promises.push(new Promise((resolve2,reject2)=>{
            this.getResourceByIRI(object[key]).subscribe(res=>{
              object[key]=res;
              resolve2(true);
            },err=>reject2(err));
          }));
        }
      }
      Promise.all(promises).then(res=>{
        resolve1(true);
      }).catch(err=>reject1(err));
    })
  }

  getResourceByIRI(IRI){
    return this.eoHttp.get(IRI);
  }

  getResourcesByIRIArray(IRIArray){
    let promises = [];
    if(!IRIArray){
      return promises;
    }
    for(let IRI of IRIArray){
      promises.push(this.eoHttp.get(IRI).toPromise());
    }
    return Promise.all(promises);
  }

  testFinalText(text){
    if(text.length === 0){return true;}
    var result = text.replace(/(<p>[a-zA-Z0-9.,\-\ áéóíúÁÉÍÓÚÑñ€€:;_–%$#@|¿?!"·’´()\+çÇüàèìòùÀÈÌÒÙ&ªº\\\/”…']*<\/p>)+/gi,"");
    return result.length === 0;
  };

}

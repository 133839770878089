import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logStyle'
})
export class LogStylePipe implements PipeTransform {

  transform(log: any, property:string ): any {
    switch(property){
      case 'background':
        return this.logBackground(log);
      case 'icon':
        return this.logIcon(log);
    }
    return null;
  }

  logBackground(log){
    if([1,2,9,12,18,19,21,22].includes(log.leadReceivedActionTypeId)){
      return 'btn-warning';
    }
    else if([3,6,7,10].includes(log.leadReceivedActionTypeId)){
      return 'btn-danger';
    }
    else if([5,11,13,14,16,15,20,500].includes(log.leadReceivedActionTypeId)){
      return 'btn-success';
    }
    else if(log.leadReceivedActionTypeId==25){
      return 'btn-primary';
    }
    else{
      return '';
    }
  }

  logIcon(log){
    if([3,6,7,10,22].includes(log.leadReceivedActionTypeId)){
      return 'fas fa-times';
    }
    else if([5,8,11].includes(log.leadReceivedActionTypeId)){
      return 'fa fa-check';
    }
    else if([2,9].includes(log.leadReceivedActionTypeId)){
      return 'far fa-save';
    }
    else if(log.leadReceivedActionTypeId == 21){
      return 'fas fa-plus';
    }
    else if([12,13,14,15,500].includes(log.leadReceivedActionTypeId)){
      return 'far fa-clock';
    }
    else if(log.leadReceivedActionTypeId == 18){
      return 'fas fa-lock';
    }
    else if(log.leadReceivedActionTypeId == 19){
      return 'fas fa-unlock';
    }
    else if(log.leadReceivedActionTypeId == 25){
      return 'fa fa-calendar';
    }
    else if(log.leadReceivedActionTypeId == 16){
      return 'fas fa-thumbs-up';
    }
    else if(log.leadReceivedActionTypeId == 1){
      return 'fas fa-plus';
    }
    else{
      return '';
    }
  }

}

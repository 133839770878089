import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'functionPipe'
})
export class FunctionPipe implements PipeTransform {

  transform(row: any, functionToExec:any, params?:any): any {
    if(!functionToExec){
      return '';
    }
    if(params){
      return functionToExec(row, ...params);
    }
    else{
      return functionToExec(row);
    }
  }

}

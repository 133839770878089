import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialtiesPipe } from 'src/app/pipes/specialties.pipe';
import { MetaFilterPipe } from 'src/app/pipes/meta-filter.pipe';
import { PartnerFilterPipe } from 'src/app/pipes/partner-filter.pipe';
import { TimeFromTodayPipe } from 'src/app/pipes/time-from-today.pipe';
import { ContactAttemptPipe } from 'src/app/pipes/contact-attempt.pipe';
import { SecondsToTimePipe } from 'src/app/pipes/seconds-to-time.pipe';
import { PayMethodPipe } from 'src/app/pipes/pay-method.pipe';
import { LogStylePipe } from 'src/app/pipes/log-style.pipe';
import { SanitizerPipe } from 'src/app/pipes/sanitizer.pipe';
import { PartnerStatusPipe } from 'src/app/pipes/partner-status.pipe';
import { ObjectFilterPipe } from 'src/app/pipes/object-filter.pipe';
import { ContentFieldsPipe } from 'src/app/components/shared/abstract-table/content-fields.pipe';
import { LogBadgePipe } from 'src/app/pipes/log-badge.pipe';
import { FunctionPipe } from 'src/app/pipes/function.pipe';
import { HasCredentialPipe } from '../pipes/has-credential.pipe';
import { ContactIconPipe } from '../pipes/contact-icon.pipe';

@NgModule({
  declarations: [
    SpecialtiesPipe,
    MetaFilterPipe,
    PartnerFilterPipe,
    TimeFromTodayPipe,
    ContactAttemptPipe,
    SecondsToTimePipe,
    PayMethodPipe,
    LogStylePipe,
    SanitizerPipe,
    PartnerStatusPipe,
    ObjectFilterPipe,
    ContentFieldsPipe,
    LogBadgePipe,
    FunctionPipe,
    HasCredentialPipe,
    ContactIconPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SpecialtiesPipe,
    MetaFilterPipe,
    PartnerFilterPipe,
    TimeFromTodayPipe,
    ContactAttemptPipe,
    SecondsToTimePipe,
    PayMethodPipe,
    LogStylePipe,
    SanitizerPipe,
    PartnerStatusPipe,
    ObjectFilterPipe,
    ContentFieldsPipe,
    LogBadgePipe,
    FunctionPipe,
    HasCredentialPipe,
    ContactIconPipe
  ]
})
export class PipesModule { }

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { to } from 'src/app/globals/utils';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private authService:AuthService, private router:Router){  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let [err, isLogged] = await to(this.authService.isLoggedIn());
    if(state.url!='/login' && state.url!='/forgot-password'){
      if(!isLogged){
        this.authService.urlWhenReloging = state.url != '/login' ? state.url : null;
        this.router.navigateByUrl('/login');
      }else{
        return true;
      }
    }
    if(state.url=='/login' || state.url=='/forgot-password'){
      if(isLogged){
        this.router.navigateByUrl('/leads/list');
      }else{
        return true;
      }
    }
  }
}

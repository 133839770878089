import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partnerStatus'
})
export class PartnerStatusPipe implements PipeTransform {

  transform(partner: any): any {
    if(!partner){
      return 'd-none';
    }
    let text = 'text-success';
    if(partner.Credits == null){
      text = 'text-info';
    }
    else if(partner.isZombie == 1){
      text = 'text-warning';
    }
    else if(partner.FreeTrialExpired == 1){
      text = 'text-danger';
    }
    return text;
  }

}

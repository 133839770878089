import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PopUpService } from '../../services/pop-up.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements AfterViewInit {

  @ViewChild('spinnerPopUp', { static: true }) spinnerPopUp:ElementRef

  constructor(public popUpService:PopUpService) {}

  ngAfterViewInit(){
    this.popUpService.loadingRef = this.spinnerPopUp;
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  transform(seconds:any): any {
    if(seconds) {
      var sec_num:any = parseInt(seconds, 10); // don't forget the second param
      var hours:any   = Math.floor(sec_num / 3600);
      var minutes:any = Math.floor((sec_num - (hours * 3600)) / 60);
      seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return hours+':'+minutes+':'+seconds;
  }
  return '';
  }

}

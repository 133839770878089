import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { PagesComponent } from './components/pages/pages.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './modules/pipes.module';
import { HasCredentialPipe } from './pipes/has-credential.pipe';
import { TokenInterceptor } from './services/token-interceptor.service';
import { IdInterceptor } from './services/id-interceptor.service';
import { TrimInterceptor } from './services/trim-interceptor.service';
import { CacheInterceptor } from './services/cache-interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    PipesModule,
    SweetAlert2Module.forRoot()
  ],
  providers: [DatePipe, TitleCasePipe, HasCredentialPipe, 
    { provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: IdInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: TrimInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

(function(mod) {
    if (typeof exports == "object" && typeof module == "object") // CommonJS
      mod(require("../../../node_modules/codemirror/lib/codemirror"),  require("../../../node_modules/codemirror/addon/mode/multiplex"),
        require("../../../node_modules/codemirror/addon/mode/overlay"));
    else if (typeof define == "function" && define.amd) // AMD
      define(["../../../node_modules/codemirror/lib/codemirror", "../../../node_modules/codemirror/addon/mode/multiplex"], mod);
    else // Plain browser env
      mod(CodeMirror);
  })(function(CodeMirror) {
    "use strict";
    CodeMirror.defineMode("htmltwig", function(config, parserConfig) {
        return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || "text/html"), CodeMirror.getMode(config, "twig"));
    });
});
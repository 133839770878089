import {
    animation, trigger, animateChild, group, keyframes,
    transition, animate, style, query, useAnimation, state
} from '@angular/animations';

export const fadeTrigger = trigger("fadeOpacity", [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
    ]),
    transition(':leave', [
        animate('0.5s', style({ opacity: 0 }))
    ])
]);

export const slideUpDown = trigger('slideUpDown', [
    transition(':enter', [
        style({ height: '0px', overflow: 'hidden'}),
        animate('0.3s ease-out', style({ height : '*' })),
    ]),
    transition(':leave', [
        style({ overflow: 'hidden'}),
        animate('0.3s ease-out', style({ height: '0px' }))
    ]),
]);

export const slideInAnimation = animation([
    style({ transform: 'translateX({{ from }})', opacity: 0 }),
    animate('{{ timings }}', style({ transform: 'translateX(0)', opacity: 1 }))
]);

export const slideOutAnimation = animation([
    animate(
        '{{ timings }}',
        style({ transform: 'translateX({{ to }})', opacity: 0 })
    )
]);

export const slideIn = animation([
    animate(
        '{{ time }}',
        keyframes([
            style({ transform: 'translateX({{from}})', opacity:0, offset: 0 }),
            style({ opacity:1, offset: 0.66 }),
            style({ transform: 'translateX(0)', offset: 1 })
        ])
    ),
]);

export const transform = animation([
    style({transform: 'translateX({{start}})'}),
    animate('{{ timings }}', style({ transform: 'translateX(end)' }))
]);

export const opacity = animation([
    style({opacity: '{{start}}'}),
    animate('{{ timings }}', style({opacity: '{{end}}'}))
]);
export const TOKEN = '?eotoken=eoffer';
export const TOCVERSION = 4;

//LEAD ACTIONS
export const SAME_EVENT = 2;
export const VALIDATED_EVENT = 5;
export const BID_EVENT = 9;
export const PREVALIDATED_EVENT = 12;
export const SCHEDULED_EVENT = 14;
export const PREVALIDATION_SCHEDULED_EVENT = 15;
export const REJECTED_EVENT = 10;
export const APPROVED_EVENT = 13;

export enum FLOWS {OUTGOING = 'OUTGOING', INCOMING = 'INCOMING'};

export const ADMIN2URL = "http://admin2.easyoffer.es/#/"

export const LEAD_ACTIONS = { SAME_EVENT: SAME_EVENT, VALIDATED_EVENT: VALIDATED_EVENT, BID_EVENT: BID_EVENT, PREVALIDATED_EVENT: PREVALIDATED_EVENT,
    SCHEDULED_EVENT: SCHEDULED_EVENT, PREVALIDATION_SCHEDULED_EVENT: PREVALIDATION_SCHEDULED_EVENT, REJECTED_EVENT: REJECTED_EVENT, APPROVED_EVENT: APPROVED_EVENT }

export const METHODS = { ONLINE : 'online', LOCATION : 'location', DISTANCE : 'distance' };
export const MODELS = { SPECIALTIES: 'specialties' };

export const CACHE_ENDPOINTS = ['/api/lead_contact_channels', '/api/lead_received_action_types', '/api/lead_contact_action_types', '/api/industries', '/api/provinces'];

export const streetTypes = [
    {id:1, name: "Calle"},
    {id:2, name: "Plaza"},
    {id:3, name: "Avenida"},
    {id:4, name: "Paseo"},
    {id:5, name: "Otro"},
    {id:6, name: "Carrer"},
    {id:7, name: "Travessera"},
    {id:8, name: "Rambla"},
    {id:9, name: "Gran Vía"},
    {id:10, name: "Vía"},
    {id:11, name: "Plaça"},
    {id:12, name: "Raval"},
    {id:13, name: "Passatge"},
    {id:14, name: "Ronda"},
    {id:15, name: "Glorieta"},
    {id:16, name: "Parque"},
    {id:17, name: "Passeig"},
    {id:18, name: "Carretera"},
    {id:19, name: "Pasaje"},
    {id:20, name: "Rúa"},
    {id:21, name: "Polígono"},
    {id:22, name: "Camino"},
    {id:23, name: "Travesía"},
    {id:24, name: "Urbanización"},
    {id:25, name: "Transversal"},
    {id:26, name: "Cerro"},
    {id:27, name: "Camí"},
    {id:28, name: "Avinguda"},
    {id:29, name: "Bulevar"},
    {id:30, name: "Alameda"},
    {id:31, name: "Muralla"},
    {id:32, name: "Barriada"},
    {id:33, name: "Barrio"},
]

export const ATTEMPTOPTIONS = [
    {"Id":"0","Name":"Doesn't pick up","BackgroundClass":"yellow-bg","Icon":"fa fa-phone"},
    {"Id":"1","Name":"Successful call","BackgroundClass":"navy-bg","Icon":"fa fa-phone"},
    {"Id":"2","Name":"Email sent","BackgroundClass":"navy-bg","Icon":"fa fa-envelope"},
    {"Id":"3","Name":"SMS sent","BackgroundClass":"navy-bg","Icon":"fa fa-comment"},
    {"Id":"4","Name":"Whatsapp sent","BackgroundClass":"navy-bg","Icon":"fab fa-whatsapp"}
];

export const CASETYPES: any = [
    {index: '1.1', value: '1.1 - CONVENIO REGULADOR'},
    {index: '1.2', value: '1.2 - MODIFICACIÓN DE MEDIDAS DE CONVENIO REGULADOR'},
    {index: '1.3', value: '1.3 - PRESENTACIÓN DEMANDA DE MEDIDAS PATERNO FILIALES'},
    {index: '1.4', value: '1.4 - MODIFICACIÓN DE MEDIDAS PATERNO FILIALES'},
    {index: '1.5', value: '1.5 - CONTESTACION DEMANDA DE MODIFICACION DE MEDIDAS PATERNO FILIALES'},
    {index: '1.6', value: '1.6 - RECLAMACION PENSION DE ALIMENTOS'},
    {index: '1.7.1', value: '1.7.1 - RECONOCIMIENTO DE PATERNIDAD - PADRE'},
    {index: '1.7.2', value: '1.7.2 - RECONOCIMIENTO DE PATERNIDAD - MADRE'},
    {index: '1.8', value: '1.8 - RECUPERACION TUTELA DE UN MENOR'},
    {index: '1.9', value: '1.9 - DIVORCIO / SEPARACION'},
    {index: '1.10', value: '1.10 - RECONCILIACION FAMILIAR (MATRIMONIO TRAS SEPARACION)'},
    {index: '1.11', value: '1.11 - NULIDAD MATRIMONIAL ECLESIASTICA'},
    {index: '1.12.1', value: '1.12.1 - ADOPCION MENOR DE EDAD'},
    {index: '1.12.2', value: '1.12.2 - ADOPCION INTERNACIONAL DE MENOR DE EDAD'},
    {index: '1.12.3', value: '1.12.3 - ADOPCION MAYOR DE EDAD'},
    {index: '1.13.1', value: '1.13.1 - CAMBIO REGIMEN ECONOMICO MATRIMONIAL - DE GANANCIALES A SEPARACION DE BIENES'},
    {index: '1.13.2', value: '1.13.2 - CAMBIO REGIMEN ECONOMICO MATRIMONIAL - DE SEPARACION DE BIENES A GANANCIALES'},
    {index: '1.14', value: '1.14 - LIQUIDACION REGIMEN ECONOMICO MATRIMONIAL'},
    {index: '1.15', value: '1.15 - DERECHO VISITAS PARA NIETOS (ABUELOS, TIOS, HERMANOS)'},
    {index: '1.16', value: '1.16 - CONVENIO REGULADOR PARA MASCOTAS'},
    {index: '1.17', value: '1.17 - CAPITULACIONES MATRIMONIALES'},
    {index: '1.18', value: '1.18 - SEPARACION DE PAREJA DE HECHO SIN BIENES NI HIJOS EN COMUN'},
    {index: '1.19', value: '1.19 - INCUMPLIMIENTO REGIMEN DE VISITAS'},
    {index: '1.20', value: '1.20 - CONTESTACION / RECURSO SENTENCIA DE FAMILIA'},
    {index: '1.21', value: '1.21 - ASUNTO ESTANDAR DE FAMILIA'},
    {index: '1.22', value: '1.22 - MEDIACION FAMILIAR'},
    {index: '1.23', value: '1.23 - SINDROME DE ALIENACION PARENTAL'},
    {index: '1.24', value: '1.24 - EMANCIPACION DE MENOR DE EDAD'},
    {index: '1.25', value: '1.25 - GESTACION SUBROGADA'},
    {index: '2.1', value: '2.1 - CLAUSULA SUELO'},
    {index: '2.2', value: '2.2 - RECLAMACION GASTOS CONSTITUCION HIPOTECA'},
    {index: '2.3', value: '2.3 - CLAUSULA IRPH'},
    {index: '2.4', value: '2.4 - HIPOTECA MULTIDIVISA'},
    {index: '2.5', value: '2.5 - REVISION DE HIPOTECA'},
    {index: '2.6', value: '2.6 - PREFERENTES'},
    {index: '2.7', value: '2.7 - DACIÓN EN PAGO'},
    {index: '2.8', value: '2.8 - EJECUCION HIPOTECARIA: DEFENSA/PARALIZACION'},
    {index: '2.9', value: '2.9 - EJECUCION HIPOTECARIA: RENEGOCIACION'},
    {index: '2.10', value: '2.10 - RESCATE DE INVERSION O PLAN DE PENSION'},
    {index: '2.11', value: '2.11 - TARJETAS REVOLVING'},
    {index: '2.12', value: '2.12 - ASUNTO ESTANDAR DE BANCARIO'},
    {index: '2.13', value: '2.13 - ABUSOS BANCARIOS: COMISIONES E INTERESES'},
    {index: '2.14', value: '2.14 - AVALES BANCARIOS'},
    {index: '2.15', value: '2.15 - ASESORAMIENTO INVERSION'},
    {index: '2.16', value: '2.16 - RECLAMACION DE CANTIDAD A BANCO'},
    {index: '3.1', value: '3.1 - FRAUDE MOTORES DIESEL'},
    {index: '3.2', value: '3.2 - AFECTADOS CARTEL DE FABRICANTES DE CAMIONES'},
    {index: '3.3.1', value: '3.3.1 - SERVICIO DE IGUALA - PARTICULAR'},
    {index: '3.3.2', value: '3.3.2 - SERVICIO DE IGUALA'},
    {index: '3.4.1', value: '3.4.1 - VICIOS OCULTOS EN LA COMPRA DE UN VEHICULO - COMPRADOR / RECLAMANTE'},
    {index: '3.4.2', value: '3.4.2 - VICIOS OCULTOS EN LA COMPRA DE UN VEHICULO - VENDEDOR / RECLAMADO'},
    {index: '3.5', value: '3.5- VICIOS OCULTOS EN LA COMPRA DE UN CASA'},
    {index: '3.6', value: '3.6 - REDACCION DE UN CONTRATO'},
    {index: '3.7', value: '3.7 - REVISION DE DOCUMENTOS'},
    {index: '3.8', value: '3.8 - INCUMPLIMIENTO DE CONTRATO MERCANTIL'},
    {index: '3.9', value: '3.9 - RESPONSABILIDAD CIVIL POR ACCIDENTE'},
    {index: '3.10', value: '3.10 - EXEQUATUR'},
    {index: '3.11', value: '3.11 - DIVISION DE PROINDIVISO'},
    {index: '3.12', value: '3.12 - OBRA MAL EJECUTADA'},
    {index: '3.13', value: '3.13 - SOLICITUD INCAPACITACION JUDICIAL'},
    {index: '3.14', value: '3.14 - CONTAMINACION ACUSTICA - AFECTADO'},
    {index: '3.15', value: '3.15 - VUELOS CANCELADOS'},
    {index: '3.16', value: '3.16 - ASUNTO ESTANDAR DE DERECHO CIVIL'},
    {index: '3.17', value: '3.17 - COVID 19 - RESPONSABILIDAD PATRIMONIAL DEL ESTADO'},
    {index: '3.18', value: '3.18 - COVID 19 - RESPONSABILIDAD PATRIMONIAL DE EMPRESA'},
    {index: '4.1', value: '4.1 - NEGLIGENCIA MEDICA'},
    {index: '4.2', value: '4.2 - MALA PRAXIS ABOGADO'},
    {index: '4.3', value: '4.3 - MALA PRAXIS PROFESIONAL'},
    {index: '5.1', value: '5.1 - ASESOR CONTABLE, FISCAL Y LABORAL'},
    {index: '5.2', value: '5.2 - ALTA COMO AUTONOMO'},
    {index: '5.3', value: '5.3 - PLAN DE VIABILIDAD'},
    {index: '5.4', value: '5.4 - REGISTRO DE PATENTE, MARCA O DISEÑO'},
    {index: '5.5', value: '5.5 - RENOVACION DE PATENTE, MARCA O DISEÑO'},
    {index: '5.6', value: '5.6 - RECLAMACION POR COPIA DE PATENTE, MARCA O DISEÑO'},
    {index: '5.7', value: '5.7 - ADAPTACION REGLAMENTO GENERAL DE PROTECCION DE DATOS'},
    {index: '5.8', value: '5.8 - AUDITORIA'},
    {index: '5.9', value: '5.9 - CIERRE Y LIQUIDACION DE EMPRESA'},
    {index: '5.10', value: '5.10 - CREACION DE UNA EMPRESA'},
    {index: '5.11', value: '5.11 - VULNERACION LEY DE PROTECCION DE DATOS'},
    {index: '5.12', value: '5.12 - IMPORTACION / EXPORTACION DE PRODUCTOS'},
    {index: '5.13', value: '5.13 - ASUNTO ESTANDAR DE DERECHO CIVIL'},
    {index: '5.14', value: '5.14 - JUNTA DE ACCIONISTAS - CONVOCAR'},
    {index: '5.15', value: '5.15 - JUNTA DE ACCIONISTAS - ANULAR ACTA'},
    {index: '5.16', value: '5.16 - SALIDA O RENUNCIA DE SOCIOS DE UNA EMPRESA'},
    {index: '5.17', value: '5.17 - VENTA O CESION DE EMPRESA'},
    {index: '5.18', value: '5.18 - PROBLEMAS CON ADUANAS'},
    {index: '5.19', value: '5.19 - FRANQUICIAS - FRANQUICIADOR (EMPRESA) - PROBLEMAS'},
    {index: '5.20', value: '5.20 - FRANQUICIAS - FRANQUICIADOR (EMPRESA) - APERTURA FRANQUICIA'},
    {index: '5.21', value: '5.21 - FRANQUICIAS - FRANQUICIADO (CLIENTE)'},
    {index: '5.22', value: '5.22 - CANCELACION DE CONTRATO'},
    {index: '6.1', value: '6.1 - ACCIDENTE DE TRÁFICO'},
    {index: '7.1', value: '7.1 - ACCIDENTE LABORAL'},
    {index: '7.2', value: '7.2 - DESPIDO LABORAL'},
    {index: '7.3', value: '7.3 - SOLICITUD INCAPACIDAD LABORAL'},
    {index: '7.4', value: '7.4 - SOLICITUD DE PENSION DE JUBILACION O PREJUBILACION'},
    {index: '7.5', value: '7.5 - SOLICITUD DE PENSION DE VIUDEDAD'},
    {index: '7.6', value: '7.6 - SOLICITUD DE PENSION NO CONTRIBUTIVA'},
    {index: '7.7', value: '7.7 - CALCULO DE PENSION DE JUBILACION'},
    {index: '7.8', value: '7.8 - PENSION DE ORFANDAD'},
    {index: '7.9', value: '7.9 - RECONOCIMIENTO DE DISCAPACIDAD / MINUSVALIA'},
    {index: '7.10', value: '7.10 - REVISION DE GRADO DE DISCAPACIDAD'},
    {index: '7.11', value: '7.11 - SOLICITUD REDUCCION DE JORNADA LABORAL'},
    {index: '7.12', value: '7.12 - ACOSO LABORAL'},
    {index: '7.13', value: '7.13 - ERTE (EXPEDIENTE DE REGULACION TEMPORAL DE EMPLEO)'},
    {index: '7.14', value: '7.14 - SANCIONES LABORALES'},
    {index: '7.15', value: '7.15 - RECLAMACION IMPAGO SALARIAL'},
    {index: '7.16', value: '7.16 - RECLAMACION CONDICIONES LABORALES'},
    {index: '7.17', value: '7.17 - ASISTENCIA EMPLEADO ERTE'},
    {index: '7.18', value: '7.18 - RECLAMACION COBRO PARO O ERTE'},
    {index: '7.19', value: '7.19 - ASUNTO ESTANDAR DE DERECHO LABORAL'},
    {index: '7.20', value: '7.20 - SALIDA PACTADA DE EMPRESA DE UN TRABAJADOR'},
    {index: '7.21', value: '7.21 - INCUMPLIMIENTO CONVENIO COLECTIVO'},
    {index: '7.22', value: '7.22 - INTERINOS - ADMINISTRACION PUBLICA'},
    {index: '7.23', value: '7.23 - CONVERSION DE CONTRATO TEMPORAL EN INDEFINIDO'},
    {index: '7.24', value: '7.24 - EXCEDENCIA - SOLICITUD'},
    {index: '7.25', value: '7.25 - EXCEDENCIA - REINCORPORACION'},
    {index: '7.26', value: '7.26 - SOLICITUD DE BAJA POR MATERNIDAD, PATERNIDAD O LACTANCIA'},
    {index: '7.27', value: '7.27 - TRABAJADOR SIN CONTRATO'},
    {index: '7.28', value: '7.28 - RECLAMACION A TRABAJADOR'},
    {index: '7.29', value: '7.29 - INCUMPLIMIENTO DE PRECONTRATO DE TRABAJO'},
    {index: '7.30', value: '7.30 - DESPIDO - ASISTENCIA A EMPRESA'},
    {index: '7.31', value: '7.31 - RECLAMACION A FOGASA'},
    {index: '7.32', value: '7.32 - FALSO AUTONOMO'},
    {index: '7.33', value: '7.33 - IMPUGNACION ALTA MEDICA'},
    {index: '7.34', value: '7.34 - RECLAMACION A EMPRESA'},
    {index: '8.1', value: '8.1 - RECLAMACIÓN DE CANTIDAD - ACREEDOR/RECLAMADOR'},
    {index: '8.2', value: '8.2 - RECLAMACIÓN DE CANTIDAD - DEUDOR/RECLAMADO'},
    {index: '8.3', value: '8.3 - OPOSICION PROCESO MONITORIO'},
    {index: '8.4', value: '8.4 - RENEGOCIACION DE DEUDAS'},
    {index: '8.5', value: '8.5 - BORRAR DATOS DE FICHERO DE MOROSOS (ASNEF)'},
    {index: '9.1', value: '9.1 - GESTION INTEGRAL DE HERENCIAS'},
    {index: '9.2', value: '9.2 - LIQUIDACION DEL IMPUESTO DE SUCESIONES'},
    {index: '9.3', value: '9.3 - IMPUGNACION DE TESTAMENTO'},
    {index: '9.4', value: '9.4 - RECLAMACION DE LEGITIMA'},
    {index: '9.5', value: '9.5 - REDACCION DE TESTAMENTO'},
    {index: '9.6', value: '9.6 - ASUNTO ESTANDAR DE DERECHO DE SUCESIONES'},
    {index: '9.7', value: '9.7 - DONACION'},
    {index: '10.1', value: '10.1 - INICIAR DESAHUCIO - PROPIETARIO'},
    {index: '10.2', value: '10.2 - PARALIZAR DESAHUCIO - INQUILINO'},
    {index: '10.3.1', value: '10.3.1 - COMPRAVENTA DE VIVIENDA'},
    {index: '10.3.2', value: '10.3.2 - REVISION CONTRATO DE ARRAS'},
    {index: '10.3.3', value: '10.3.3 - REDACCION CONTRATO DE ARRAS'},
    {index: '10.4', value: '10.4 - IMPUGNACION ACTAS COMUNITARIAS'},
    {index: '10.5', value: '10.5 - PROBLEMA CON CATASTRO'},
    {index: '10.6', value: '10.6 - PROBLEMA DE LINDES Y MEDIANIAS'},
    {index: '10.7', value: '10.7 - DAÑOS VIVIENDA - PROVOCADOS POR INQUILINO'},
    {index: '10.8', value: '10.8 - DAÑOS VIVIENDA - PROVOCADOS POR VECINO'},
    {index: '10.9', value: '10.9 - USUCAPION'},
    {index: '10.10', value: '10.10 - CANCELACION CONTRATO MULTIPROPIEDAD'},
    {index: '10.11', value: '10.11 - RECLAMACION FIANZA'},
    {index: '10.12', value: '10.12 - INFRACCIONES URBANISTICAS'},
    {index: '10.13', value: '10.13 - ASUNTO ESTANDAR DE URBANISMO'},
    {index: '10.14', value: '10.14 - ASUNTO ESTANDAR DE INMOBILIARIO'},
    {index: '10.15', value: '10.15 - SOLICITUD DE RECALIFICACION'},
    {index: '10.16', value: '10.16 - CANCELACION DE CONTRATO DE ARRAS'},
    {index: '10.17', value: '10.17 - EJECUCION DE CONTRATO ARRAS'},
    {index: '10.18', value: '10.18 - DAÑOS VIVIENDA - FALTA DE MANTENIMIENTO'},
    {index: '10.19', value: '10.19 - DAÑOS VIVIENDA - PROVOCADOS COMUNIDAD'},
    {index: '10.20', value: '10.20 - DAÑOS VIVIENDA - CONTESTACION RECLAMACION'},
    {index: '10.21', value: '10.21 - RECLAMACION A CASERO, INQUILINO, VECINO O COMUNIDAD (GOTERAS, HUMEDADES, FALTA MANTENIMIENTO...)'},
    {index: '11.1', value: '11.1 - LA LEY DE SEGUNDA OPORTUNIDAD'},
    {index: '11.2', value: '11.2 - CONCURSO DE ACREEDORES'},
    {index: '11.3', value: '11.3 - ASUNTO ESTANDAR DE DERECHO CONCURSAL'},
    {index: '12.1', value: '12.1 - PENAL - DELITO NO DENUNCIADO'},
    {index: '12.2', value: '12.2 - CANCELACION ANTECEDENTES PENALES'},
    {index: '12.3', value: '12.3 - CANCELACION ANTECEDENTES POLICIALES'},
    {index: '12.4', value: '12.4 - DELITO POR CONDUCCIÓN SIN CARNÉ'},
    {index: '12.5', value: '12.5 - ASISTENCIA EN JUICIO PENAL'},
    {index: '12.6', value: '12.6 - RECURSO CONTRA SENTENCIA POR DELITO PENAL'},
    {index: '12.7', value: '12.7 - AFECTADO CIERRE CLÍNICA DENTAL'},
    {index: '12.8', value: '12.8 - ESTAFA INVERSION INTERNET'},
    {index: '12.9', value: '12.9 - TRIPLE DE LA MAYOR'},
    {index: '12.10', value: '12.10 - DELITO CONTRA LA SEGURIDAD VIAL - ALCOHOLEMIA'},
    {index: '12.11', value: '12.11 - ASUNTO ESTANDAR PENAL'},
    {index: '12.12', value: '12.12 - DELITO CONTRA LA SALUD PUBLICA'},
    {index: '12.13', value: '12.13 - DELITO DE LESIONES'},
    {index: '12.14', value: '12.14 - DELITO DE ROBO / HURTO'},
    {index: '12.15', value: '12.15 - PERMISOS PENITENCIARIOS / TERCER GRADO'},
    {index: '12.16', value: '12.16 - RETIRAR UNA ORDER DE ALEJAMIENTO'},
    {index: '12.17', value: '12.17 - DENTIX - AFECTADO CIERRE CLINICA DENTAL'},
    {index: '13.1', value: '13.1 - PLUSVALIA'},
    {index: '13.2', value: '13.2 - SANCIONES AIRBNB'},
    {index: '13.3', value: '13.3 - ASESORAMIENTO AIRBNB'},
    {index: '13.4', value: '13.4 - MULTA DE TRAFICO'},
    {index: '13.5', value: '13.5 - MULTA ADMINISTRATIVA'},
    {index: '13.6', value: '13.6 - HOMOLOGACION DE TITULOS'},
    {index: '13.7', value: '13.7 - RECURSO CONTENCIOSO ADMINISTRATIVO'},
    {index: '13.8', value: '13.8 - RECURSO CASACION TRIBUNAL SUPREMO'},
    {index: '13.9', value: '13.9 - EXPROPIACION FORZOSA'},
    {index: '13.10', value: '13.10 - INDULTO'},
    {index: '13.11', value: '13.11 - CAMBIO TITULARIDAD DE VEHICULO'},
    {index: '13.12', value: '13.12 - ASUNTO ESTANDAR DE DERECHO ADMINISTRATIVO'},
    {index: '13.13', value: '13.13 - SOLICITUD DE LICENCIA'},
    {index: '13.14', value: '13.14 - RECURSO DENEGACION LICENCIA'},
    {index: '14.1', value: '14.1 - SOLICITUD PERMISO DE RESIDENCIA'},
    {index: '14.2', value: '14.2 - SOLICITUD PERMISO DE TRABAJO'},
    {index: '14.3', value: '14.3 - RENOVACION PERMISO DE RESIDENCIA'},
    {index: '14.4', value: '14.4 - RENOVACION PERMISO DE TRABAJO'},
    {index: '14.5', value: '14.5 - SOLICITUD REAGRUPACION FAMILIAR'},
    {index: '14.6', value: '14.6 - SOLICITUD ASILO POLITICO'},
    {index: '14.7', value: '14.7 - SOLICITUD VISADOS PAISES EXTRANJEROS'},
    {index: '14.8', value: '14.8 - INSCRIPCION PAREJA DE HECHO'},
    {index: '14.9', value: '14.9 - MATRIMONIO MIXTO'},
    {index: '14.10', value: '14.10 - SOLICITUD DE LA NACIONALIDAD ESPAÑOLA'},
    {index: '14.11', value: '14.11 - RETENCION EN AEROPUERTO'},
    {index: '14.12', value: '14.12 - PARALIZACION DE EXPLUSION DE EXTRANJERO'},
    {index: '14.13', value: '14.13 - SOLICITUD PERMISO DE RESIDENCIA POR INVERSION ( GOLDEN VISA )'},
    {index: '14.14', value: '14.14 - CONTENCIOSO ADMINISTRATIVO POR CONTESTACION A SOLICITUD DE NACIONALIDAD ESPAÑOLA'},
    {index: '14.15', value: '14.15 - RENOVACION DE NIE'},
    {index: '14.16', value: '14.16 - ASUNTO ESTANDAR DE EXTRANJERIA'},
    {index: '15.1', value: '15.1 - DECLARACION DE LA RENTA'},
    {index: '15.2', value: '15.2 - DECLARACIONES DE IMPUESTOS DE NACIONALES NO RESIDENTES'},
    {index: '15.3', value: '15.3 - DECLARACION DE BIENES EN EL EXTRANJERO'},
    {index: '15.4', value: '15.4 - INSPECCION DE HACIENDA'},
    {index: '15.5', value: '15.5 - PARALELA DE HACIENDA'},
    {index: '15.6', value: '15.6 - ASUNTO ESTANDAR DE FISCAL'},
    {index: '15.7', value: '15.7 - ASESOR FISCAL PARA CRIPTOMONEDAS (BITCOIN)'},
    {index: '16.1', value: '16.1 - CONSULTA PUNTUAL CON COSTE'},
    {index: '17.1', value: '17.1 - RECLAMACION SEGURO'},
    {index: '18.1', value: '18.1 - ASUNTO DERECHO MILITAR ESTANDAR'},
    {index: '19.1', value: '19.1 - ESTAFA EN COMRPA DE PRODUCTO'},
    {index: '19.2', value: '19.2 - ASUNTO ESTANDAR DE CONSUMIDORES'},
];

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metaFilter'
})
export class MetaFilterPipe implements PipeTransform {

  transform(row: any, filter: any): any {
    if(!(row && (row.Meta || row.meta))){
      return ''
    }
    let meta = row.Meta || row.meta;
    let specialties = [];
    meta.map(el=>{
      if(el && (el.MetaKey==filter || el.key==filter)){
        specialties.push((el.MetaValue || el.value));
      }
    });
    let retValue = specialties.join(', ');

    return retValue;
  }

}

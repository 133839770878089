import { Pipe, PipeTransform } from '@angular/core';
import { TokenService } from '../services/token.service';

@Pipe({
  name: 'hasCredential'
})
export class HasCredentialPipe implements PipeTransform {

  constructor(private tokenService:TokenService){}

  transform(value:string[]|string|undefined|null): any {
    if(!value){
      return true;
    }
    if(!this.tokenService.decoded){
      return false;
    }
    if(Array.isArray(value)){
      value.map((permit)=>{
        if(this.tokenService.decoded.roles.includes(permit))
          return true;
      });
      return false || this.tokenService.decoded.roles.includes('SUPER_USER');
    }
    else{
      return this.tokenService.decoded.roles.includes(value) || this.tokenService.decoded.roles.includes('SUPER_USER');
    }
  }

}

export const menuitems = [
    {icon: 'fas fa-tachometer-alt', label: 'Index', url: '/index'},
    {icon: 'fas fa-building', credentials:['ORGANIZATION_READ'], label: 'Organizations', url: '/organizations/list'},
    // {icon: 'far fa-building', label: 'Organizations', children: 
    //     [{label: 'List', credentials:['ORGANIZATION_READ'], url: '/organizations/list'}, 
    //     {label: 'Create', credentials:['ORGANIZATION_CREATE'], url: '/organizations/create'}]
    // },
    {icon: 'fas fa-sitemap', label: 'Partners', children: 
        [{label: 'List', credentials:['PARTNER_READ'], url: '/partners/list'}, 
        {label: 'Pending', credentials:['PARTNER_LEAD_READ'], url: '/partners/default_partners_list'},
        {label: 'Create', credentials:['PARTNER_CREATE'], url: '/partners/create'},
        // {label: 'Partner Log', credentials:['PARTNER_LOG_READ'], url: '/partners/admin_activity'},
        // {label: 'Profiles', credentials:['PARTNER_READ'], url: '/partners/partner_profiles_list'},
        // {label: 'Groups', url: '/partners/partner_groups_list'},
        // {label: 'Invoices', credentials:['INVOICE_READ'], url: '/partners/partner_invoices_list'}
        ]
    },
    // {icon: 'fas fa-bell', label: 'Notifications', children: 
    //     [{label: 'List', credentials:['NOTIFICATION_READ'], url: '/notifications/list'}, 
    //     {label: 'Create', credentials:['NOTIFICATION_CREATE'], url: '/notifications/create'}]
    // },
    // {icon: 'fas fa-map', label: 'Locations', children: 
    //     [{label: 'List', url: '/locations/list'}, 
    //     {label: 'Boosted Locations', url: '/locations/create'}]
    // },
    {icon: 'fas fa-map', credentials:['LOCATION_READ'], label: 'Locations', url: '/locations/list'},
    // {icon: 'fas fa-map', label: 'Locations', children: 
    //     [{label: 'List', url: '/locations/list'}, 
    //     {label: 'Boosted Locations', url: '/locations/create'}]
    // },
    {icon: 'fas fa-shekel-sign', label: 'Specialties', children: 
        [{label: 'List', credentials:['SPECIALTY_READ'], url: '/specialties/list'}, 
        {label: 'Create', credentials:['SPECIALTY_CREATE'], url: '/specialties/create'}]
    },
    // {icon: 'fas fa-list-ol', credentials:['BID_READ'], label: 'Bids', url: '/bids/list'},
    // {icon: 'fas fa-book', label: 'Glossary', children: 
    //     [{label: 'List', url: '/glossary/list'}, 
    //     {label: 'Create', url: '/glossary/create'}]
    // },
    {icon: 'fas fa-film', credentials:['LEAD_UPDATE'], label: 'KODAK'},
    {icon: 'far fa-gem', label: 'Leads', children: 
        [{label: 'List', credentials:['LEAD_READ'], url: '/leads/list'}, 
        // {label: 'List to Validate', credentials:['LEAD_READ'], url: '/leads/list_validate'}, 
        // {label: 'List to Scoring', credentials:['LEAD_UPDATE'], url: '/leads/list_scoring'}, 
        // {label: 'List to Approve', credentials:['LEAD_UPDATE'], url: '/leads/list_approve'}, 
        // {label: 'List to Scheduled', credentials:['LEAD_UPDATE'], url: '/leads/list_scheduled'}, 
        // {label: 'List to Revalidate', credentials:['LEAD_UPDATE'], url: '/leads/list_revalidate'},
        {label: 'Locked Leads', credentials:['LEAD_UPDATE'], url: '/leads/list_locked'},
        {label: 'Create', credentials:['LEAD_CREATE'], url: '/leads/create'}]
    },
    {icon: 'fas fa-flask', credentials:['REPORTING_READ'], label: 'Reporting', url:'/reporting/list'},
    {icon: 'fas fa-recycle', credentials:['SUBSCRIPTION_READ'], label: 'Subscriptions', url:'/subscriptions/list'},
    {icon: 'fas fa-file', credentials:['LEAD_STATIC_FILES'], label: 'Static files', url:'/static/files'},
    {icon: 'fas fa-trophy', label: 'Boost Leads', children: 
        [{label: 'List', credentials:['BOOST_LEAD_READ'], url: '/boost-leads/list'},
        {label: 'Locked Leads', credentials:['BOOST_LEAD_UPDATE'], url: '/boost-leads/list_locked'},
        {label: 'Create', credentials:['BOOST_LEAD_CREATE'], url: '/boost-leads/create'},
        {label: 'Bulk create', credentials:['BOOST_LEAD_CREATE'], url: '/boost-leads/bulk-create'}]
    },
    {icon: 'fas fa-fire', credentials:['SUPER_USER'], label: 'Admin panel', url:'/admin-panel/admins/list'}
]
import { Pipe, PipeTransform } from '@angular/core';
import { getIdFromIRI } from '../globals/utils';

@Pipe({
  name: 'contactIcon'
})
export class ContactIconPipe implements PipeTransform {

  transform(channel): any {
    switch(getIdFromIRI(channel)){
      case '1':
        return 'fas fa-phone';
      case '2':
        return 'fas fa-envelope';
      case '3':
        return 'fas fa-comments';
      case '4':
        return 'fab fa-whatsapp';
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CACHE_ENDPOINTS } from '../globals/constants';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CacheInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((res:any) => {
      if(req.method=='GET' && req.url.includes("core19")){
        const found = CACHE_ENDPOINTS.find(endpoint=>req.url.split('?')[0].endsWith(endpoint));
        if(found){
          let expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate()+1);
          localStorage.setItem(found,JSON.stringify({expiration: expirationDate.toISOString(), value : res.body}));
        }
      }
    }));
  }

}

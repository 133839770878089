import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logBadge'
})
export class LogBadgePipe implements PipeTransform {

  transform(eventId: any): any {
    switch(eventId){
      case '5':
        return 'badge-secondary';
      case '4':
      case '44':
      case '7':
        return 'badge-success';
      case '13':
      case '10':
      case '15':
        return 'badge-danger';
      case '6':
      case '12':
      case '11':
        return 'badge-info';
      case '1':
      case '16':
        return 'badge-dark';
      case '2':
        return 'badge-purple'
      case '8':
      case '9':
      case '14':
        return 'badge-warning';
      case '3':
      case '33':
        return 'badge-sky-blue';
    }
  }

}

export const models = {
    ADMIN_ADMIN_ROLE: 'admin_admin_roles',
    ADMIN_ROLE: 'admin_roles',
    ADMIN: 'admins',
    BANK_ACCOUNT: 'bank_accounts',
    BID: 'bids',
    BID_RECEIVED: 'bids_received',
    BOOST_LEAD_CONTACT_ACTION: 'boost_lead_contact_actions',
    BOOST_LEAD_META: 'boost_lead_metas',
    BOOST_LEAD_PARTNER_COST: 'boost_lead_partner_costs',
    BOOST_LEAD: 'boost_leads',
    CONTACT_CHANNEL: 'contact_channels',
    CREDIT_CARD: 'credit_cards',
    CREDIT_EVENT_TYPE: 'credit_event_types',
    CREDIT_EVENT: 'credit_events',
    CREDIT_PACK_PRODUCT: 'credit_pack_products',
    DISCTRICT: 'districts',
    EMAIL_SETTING: 'email_settings',
    EVENT_INTEGRATION: 'event_integrations',
    INDUSTRY: 'industries',
    INVOICE: 'invoices',
    INVOICE_LINE: 'invoice_lines',
    LEAD_ACTION_TYPE: 'lead_action_types',
    LEAD_CHANNEL: 'lead_channels',
    LEAD_CONTACT_ACTION_TYPE: 'lead_contact_action_types',
    LEAD_CONTACT_ACTION: 'lead_contact_actions',
    LEAD_META: 'lead_metas',
    LEAD_NOTIFICATION_LOCATION: 'lead_notification_locations',
    LEAD_PARTNER_ASSIGNMENT: 'lead_partner_assignments',
    LEAD_RECEIVED_ACTION_TYPE: 'lead_received_action_types',
    LEAD_RECEIVED_ACTION: 'lead_received_actions',
    LEAD_RECEIVED_CONTACT_ACTION: 'lead_received_contact_actions',
    LEAD_RECEIVED_META: 'lead_received_metas',
    LEAD_RECEIVED_SCHEDULED_CONTACT: 'lead_received_scheduled_contacts',
    LEAD_RECEIVED_SPECIALTY: 'lead_received_specialties',
    LEAD_RECEIVED: 'leads_received',
    LEAD_REJECTION_REASON: 'lead_rejection_reasons',
    LEAD_TYPES: 'lead_types',
    LEAD: 'leads',
    LEAD_CONTACT_DATA: 'leads_contact_data',
    LOCATION: 'locations',
    MARKET_STATUS: 'market_statuses',
    NEIGHBOURHOOD: 'neighbourhoods',
    OFFICE: 'offices',
    ORGANIZATION_TYPE: 'organization_types',
    ORGANIZATION: 'organizations',
    PARTNER_DISCOUNT: 'partner_discounts',
    PARTNER: 'partners',
    PRODUCT_TEMPLATE: 'product_templates',
    PROFESSIONAL_ASSOCIATION: 'professional_associations',
    PRODUCT: 'products',
    PROFILE_TYPE: 'profile_types',
    PROVINCE: 'provinces',
    QUEUED_EXPORTABLE_LEAD: 'queued_exportable_leads',
    REPORT_REASON: 'report_reasons',
    REPORT_VERDICT_TYPE: 'report_verdict_types',
    REGION: 'regions',
    REPORTED_LEAD: 'reported_leads',
    REPORT: 'reports',
    STREET_TYPE: 'street_types',
    SPECIALTY: 'specialties',
    SUBSCRIPTION_BID_EVENT_TYPE: 'subscription_bid_event_types',
    SUBSCRIPTION_BID_EVENT: 'subscription_bid_events',
    SUBSCRIPTION_PRODUCT: 'subscription_products',
    SUBSCRIPTION: 'subscriptions',
    TWIG_TEMPLATE: 'twig_templates',
    USER_REVIEW: 'user_reviews'
}
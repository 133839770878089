import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor{
  
  constructor(private authService:AuthService, private router:Router) { }

  intercept(req:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
    return this.handleErrorCode(req, next);
  }

  handleErrorCode(req, next):Observable<HttpEvent<any>>{
    return next.handle(req).pipe(tap(() => {}, 
    (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          if(!req.url.includes('/auth/token_refresh')){
            this.logOut();
          }
        }
      }
    }));
  }

  logOut(){
    if(!this.authService.urlWhenReloging){
      this.authService.urlWhenReloging = this.router.url!='/login' ? this.router.url : null;
    }
    this.authService.logOut();
  }

}

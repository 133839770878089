import { Injectable, Input, ElementRef } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertType, SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PopUpService{

  @Input() loadingRef:ElementRef;
  showLoadingPopUp:boolean = false;

  constructor() { }

  swal(title:string, text:string, type:SweetAlertType, additionalOptions ={}){
    let newSwal = new SwalComponent(Object.assign({title, text, type},additionalOptions));
    return newSwal.show();
  }

  swalWithOptions(options:SweetAlertOptions){
    let newSwal = new SwalComponent(options);
    return newSwal.show();
  }

  toggleLoading(){
    this.showLoadingPopUp = !this.showLoadingPopUp;
  }

  showLoading(){
    this.showLoadingPopUp = true;
  }

  hideLoading(){
    this.showLoadingPopUp = false;
  }

}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getIdFromIRI } from '../globals/utils';

@Injectable({
  providedIn: 'root'
})
export class IdInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map((res:any) => {
      if(res && res.body){
        if(res.body['hydra:member'] || res.body['@id']){
          this.fillObjectIds(res.body);
        }
      }
      return res;
    }));
  }

  fillObjectIds(object){
    Object.entries(object).map(([key,value])=>{
      if(key=='@id'){
        object.id = getIdFromIRI(object[key]);
      }
      else if(typeof value === 'object' && value !== null){
        this.fillObjectIds(value);
      }
      else if(Array.isArray(value)){
        this.checkArrayForIRIS(value);
      }
    })
  }

  checkArrayForIRIS(array){
    array.map(elem=>{
      if(typeof elem === 'object' && elem !== null){
        this.fillObjectIds(elem);
      }
      else if(Array.isArray(elem)){
        this.checkArrayForIRIS(elem);
      }
    })
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import * as constants from '../globals/constants';

@Pipe({
  name: 'contactAttempt'
})
export class ContactAttemptPipe implements PipeTransform {

  transform(attempt: any, field: string): any {
    let options = constants.ATTEMPTOPTIONS;
    var index = 0;
    let success = attempt.IsSuccessful || attempt.successful;
    let id = attempt.ContactChannelId || attempt.channel.id;
    if(typeof success != 'undefined') {
      if (success != 0) {
        for (var i = 0; i < options.length; i++) {
          if (options[i].Id == id) {
            index = i;
          }
        }
      }

      switch (field) {
        case "Id":
          return options[index].Id;
        case "Icon":
          return options[index].Icon;
        case "Name":
          return options[index].Name;
        case "BackgroundClass":
          return options[index].BackgroundClass;
      }
    }else{
      console.log("undefined");
      return "";
    }
  }

}

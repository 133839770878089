import { Pipe, PipeTransform, Injector } from '@angular/core';
import { MetaFilterPipe } from 'src/app/pipes/meta-filter.pipe';

@Pipe({
  name: 'contentFields'
})
export class ContentFieldsPipe implements PipeTransform {

  private _pipes = {
    'metaFilter': new MetaFilterPipe(),
  };

  constructor(private injector:Injector){}

  transform(row: any, content: any): any {
    let retValue:any;

    if(content.pipe){
      return this.pipeContent(row, content.pipe, content.pipeArgs);
    }

    if(content.fields){
      retValue = this.getFieldsValue(row, content.fields);
    }
    if(!retValue && content.altFields){
      retValue = this.getFieldsValue(row, content.altFields);
    }
    return retValue;
  }

  pipeContent(row, pipeName, pipeArgs = []){
    let pipe = pipeName;
    return pipe.transform(row, pipeArgs);
  }

  getFieldsValue(row, fields){
    let retValue = row;
    for(let field of fields){
      if(retValue){
        retValue = retValue[field];
      }
      else{
        return null;
      }
    }
    return retValue;
  }

}

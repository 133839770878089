import { Injectable, HostListener } from '@angular/core';
import * as utils from 'src/app/globals/utils';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { parseJwt, to } from '../globals/utils';
import { PopUpService } from './pop-up.service';
import { EOHttpClient } from './eohttp-client.service';
import { TokenService } from './token.service';
import { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlWhenReloging:string;
  expTimeout:any;

  constructor(private http:HttpClient, private popUpService:PopUpService, private router:Router,
    private eoHttp:EOHttpClient, private tokenService:TokenService) { 
      window.addEventListener("storage",(event)=>{
        if(event.key=='refreshToken'){
          if(!event.newValue){
            clearTimeout(this.expTimeout);
            this.expTimeout = null;
          }else{
            this.setTokenService();
          }
        }
      });
    }

  login(user){
    return this.http.post(window["BASE_URL_CORE19"]+'/auth/login/admin', {username: user.Email, password: user.Password});
  }

  refresh(){
    let refreshToken = localStorage.getItem('refreshToken');
    localStorage.removeItem('refreshToken');
    return this.http.post(window["BASE_URL_CORE19"]+'/auth/token_refresh', {refresh_token: refreshToken});
  }
  saveUser(res){
    localStorage.setItem('userToken', res.token);
    localStorage.setItem('refreshToken', res.refresh_token);
    this.setTokenService();
  }

  async isLoggedIn(){
    if(this.tokenService.decoded){
      return true;
    }
    var token = this.tryLogin();
    if(token){
      this.setTokenService();
      return true;
    }
    let [err, res] = await to(this.refresh().toPromise());
    if(res && res.token){
      this.saveUser(res);
      return true;
    }
    return false;
  }

  setTokenService(){
    this.tokenService.token = localStorage.getItem('userToken');
    this.tokenService.decoded = parseJwt(this.tokenService.token);
    console.log("decoded:",this.tokenService.decoded);
    this.setExpdate();
  }

  tryLogin(){
    let token = localStorage.getItem('userToken');
    if(!token)
      return null;
    let decoded = parseJwt(token);
    let expiredDate = new Date(parseInt(decoded.exp)*1000);
    if(expiredDate < new Date()){
      return null;
    }
    return token;
  }

  setExpdate(){
    this.tokenService.expDate = new Date(this.tokenService.decoded.exp*1000);
    let now = new Date();
    let refreshDate = new Date(this.tokenService.expDate.getTime())
    refreshDate.setMinutes(refreshDate.getMinutes()-1);

    if(refreshDate < now){
      this.refresh().subscribe((res)=>{
        this.saveUser(res);
      });
    }
    else{
      let milisUntilWarning = refreshDate.getTime() - now.getTime();
      milisUntilWarning = (Math.random()*(milisUntilWarning/2))+(milisUntilWarning/2);
      console.log("MilisUntilWarning:",milisUntilWarning);
      this.expTimeout = setTimeout(()=>{
        this.refresh().subscribe((res)=>{   
          this.saveUser(res);
        },err=>{
          // this.popExpirationSwal()
          console.error("Could not refresh the session:",err);
        });
      },milisUntilWarning)
    }
  }

  popExpirationSwal(){
    let swalOptions:SweetAlertOptions = {title: 'Error refreshing the session',
    text: 'Could not refresh the session. It will close soon (1 minute or less).',
    type: 'error',
    showCancelButton: true,
    confirmButtonColor: '#1ab394',
    confirmButtonText: 'Go to Login',
    cancelButtonText: 'Finish my work'};

    this.popUpService.swalWithOptions(swalOptions).then((isConfirm)=>{
      if(isConfirm.value==true){
        this.logOut();
      }
    })
  }
  checkPassWord(password){
    var body = utils.toHttpParams({
      Email: this.tokenService.decoded.username,
      Password: password
    });
    return this.http.post(utils.formatUrl('/Admin/login'), body);
  }

  changePassword(oldPassword, newPassword, passwordVerification){
    var body = {oldPassword, newPassword, passwordVerification};
    return this.eoHttp.post('/api/me/change_password', body);
  }

  logOut(){
    clearTimeout(this.expTimeout);
    this.expTimeout = null;
    this.tokenService.token = null;
    this.tokenService.refreshToken = null;
    this.tokenService.decoded = null;
    this.tokenService.expDate = null;
    localStorage.removeItem('userToken');
    localStorage.removeItem('refreshToken');
    this.router.navigateByUrl('/login');
  }

  forgotPassword(email){
    var body = utils.toHttpParams({Email:email});
    return this.http.post(utils.formatUrl('/Admin/regeneratePassword'), body);
  };

}

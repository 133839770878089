import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'payMethod'
})
export class PayMethodPipe implements PipeTransform {

  transform(payMethodId: any): any {
    switch(payMethodId){
      case '1':
        return "Bank Transfer";
      case '2':
        return "Paypal";
      case '3':
        return "Stripe";
      case '4':
        return "Direct Debit";
    }
  }

}

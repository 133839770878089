import { HttpParams } from "@angular/common/http";
import * as constants from './constants';

declare var $;

export function JSONtoQuery(json){
    Object.entries(json).map(([key, value])=>{
        if(value===null || value===''){
            delete json[key];
        }
    });
    return '?'+$.param(json) == '?' ? '' : '?'+$.param(json);
}

export function queryToJSON(queryParams){
    this.queryParams = JSON.parse('{"' + decodeURI(queryParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
}

export function arrayDiff(arr1, arr2){
    let res = [];
    for(let elem of arr1){
        if(!arr2.includes(elem)){
            res.push(elem);
        }
    }
    return res;
}

export function getIdFromIRI(IRI){
    let chunks = IRI.split('/');
    return chunks[chunks.length-1];
}
/* Get the model from the model-names.ts */
export function getIRIFromId(id, model){
    return '/api/'+model+'/'+id;
}
export function getLocalModelFromIRI(IRI, options){
    return options.find(channel=>channel["@id"]==IRI);
}

export function prepareFilters(page, limit, fields, orderEntity, orderField, orderDirection, search, status = null){
    var order=[{'Entity':orderEntity,'Field':orderField,'Direction':orderDirection}];
    var lessThanFilters = [];
    var greaterThanFilters = [];
    var likeFilters = search.likeFilters || {};
    var nullFilters = search.nullFilters || {};
    var notNullFilters = search.notNullFilters || {};
    var equalFilters = search.equalFilters || {};
    var body:any = {
        offset: page * limit,
        limit:limit,
        orderBy: JSON.stringify(order),
        getTotal:1,
        likeFilters: JSON.stringify(likeFilters),
        lessThanFilters: JSON.stringify(lessThanFilters),
        moreThanFilters: JSON.stringify(greaterThanFilters),
        nullFilters: JSON.stringify(nullFilters),
        notNullFilters: JSON.stringify(notNullFilters),
        equalFilters: JSON.stringify(equalFilters),
        FieldsToReturn:JSON.stringify(fields),
        status: status
    };
    if(search.LocationFilter){
        body.LocationFilter = search.LocationFilter;
    }
    if(search.SpecialtyId){
        body.SpecialtyId = search.SpecialtyId;
    }
    if(fields){
        body.FieldsToReturn = JSON.stringify(fields);
    }
    return body;
}

const accentMap = {
    'á':'a', 'é':'e', 'í':'i','ó':'o','ú':'u'
};
export function accent_fold (s) {
    if (!s) { return ''; }
    var ret = '';
    for (var i = 0; i < s.length; i++) {
        ret += accentMap[s.charAt(i)] || s.charAt(i);
    }
    return ret;
};

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(base64);
};

export function to(promise) {
    return promise.then(data => {
        return [null, data];
    })
    .catch(err => [err]);
}

export function selectedVsBefore(selected, before){
    var elemsToAdd = [];
    var elemsToDelete = [];

    for(var i=0;i<before.length;i++){
        if(selected.indexOf(before[i])===-1){
            elemsToDelete.push(before[i]);
        }
    }
    for(var i=0;i<selected.length;i++){
        if(before.indexOf(selected[i])===-1){
            elemsToAdd.push(selected[i]);
        }
    }

    return [elemsToAdd, elemsToDelete];
}

export var checkChangesInEntity = function(previousObj,currentObj){
    var auxObj = {};
    for(var currentField in currentObj){
        if((typeof currentObj[currentField] !== 'object' ||  currentObj[currentField]  === null)|| currentField.toLowerCase().indexOf('date')!== -1) {
            if (fieldExistsInBothAndAreDifferents(previousObj,currentObj,currentField)
                || ((!fieldIsDefined(previousObj,currentField) || previousObj[currentField] === '')
                    && (fieldIsDefined(currentObj,currentField) || currentObj[currentField] !== '')
                    && (previousObj[currentField] !== currentObj[currentField]))) {

                auxObj[currentField] = currentObj[currentField];
            }
            else if ((fieldIsDefined(previousObj,currentField) || previousObj[currentField] !== '')
                && (!fieldIsDefined(currentObj,currentField) || currentObj[currentField] === '')
                && (previousObj[currentField] !== currentObj[currentField])) {

                auxObj[currentField] = 'null';
            }
        }//si es un objeto no lo enviamos
    }
    //añadimos el Id si lo tienen
    if(currentObj.Id) {
        auxObj["Id"] = currentObj.Id;
    }
    return auxObj;
};

export var prepareDateForDB = function(date){
    var textDate = date.getFullYear() + '-' +
        ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('00' + date.getDate()).slice(-2) + ' ' +
        ('00' + date.getHours()).slice(-2) + ':' +
        ('00' + date.getMinutes()).slice(-2) + ':' +
        ('00' + date.getSeconds()).slice(-2);
    return textDate;
};

export var fillArrayIds = function(array){
    array.map(elem=>{
        if(elem['@id'] && !elem.id){
            elem.id = getIdFromIRI(elem['@id']);
        }
    });
}

export var formatUrl = function(url){
    return window["BASE_URL_CORE17"] + url + constants.TOKEN;
}

export var toHttpParams = function(obj: Object): HttpParams {
    return Object.getOwnPropertyNames(obj)
        .reduce((p, key) => p.set(key, obj[key]), new HttpParams());
}

export var checkPosibleMistype = function(mail){
    if(mail) {
        return (mail.indexOf("test") !== -1 || mail.indexOf("gemail") !== -1 || mail.indexOf("hormail") !== -1
            || mail.indexOf("jotmail") !== -1 || mail.indexOf("htomail") !== -1 || mail.indexOf("hotmsil.com") !== -1
            || mail.indexOf("homtail.com") !== -1 || mail.indexOf("ghotmail.com") !== -1 || mail.indexOf("genial.com") !== -1
            || mail.indexOf("gmail.om") !== -1 || mail.indexOf("hotail.com") !== -1
            || mail.indexOf("gmail.como") !== -1 || mail.indexOf("homail.como") !== -1 || mail.indexOf("gmail.con") !== -1
            || mail.indexOf("gomail.com") !== -1 || mail.indexOf("gmail.cm") !== -1 || mail.indexOf("hotmail.cm") !== -1
            || mail.indexOf("gemail.como") !== -1 || mail.indexOf("gamil.com") !== -1 || mail.indexOf("hot.mail.com") !== -1
            || mail.indexOf("g.mail.com") !== -1 || mail.indexOf("email.com") !== -1 || mail.indexOf("homail.com") !== -1
            || mail.indexOf("golmail.com") !== -1);
    }else{
        return false;
    }
};

function fieldExistsInBothAndAreDifferents(prev,curr,field){
    return ((typeof prev[field] !== 'undefined' && prev[field] !== null )
        && (typeof curr[field] !== 'undefined' && curr[field] !== null )
        && (prev[field] !== curr[field]))
}

function fieldIsDefined(obj,field){
    return (typeof obj[field] !== 'undefined' && obj[field] !== null )
}

export var parseHTML = function(text){
    let output = '';
    let tree = $('<div>'+text+'</div>');
    let pChildren = $(tree).find('p *');
    for(let child of pChildren){
        $(child).contents().unwrap();
    }
    tree[0].normalize();
    let list = getTextNodesIn(tree);
    for(let element of list){
        if(element.data.trim()){
            output+='<p>'+element.data+'</p>';
        }
    }
    return output;
}
var getTextNodesIn = function(el) {
    return $(el).find(":not(iframe)").addBack().contents().filter(function() {
        return this.nodeType == 3;
    });
};

export var parseText = function(text){
    var regexStyle = /(\ [a-zA-Z-]*=\"[a-zA-Z-,.:;=%#&\'0-9\ \/=\?&\n()]*\")/ig;
    var regexStyle2 = /(\ [a-zA-Z-]*=[a-zA-ZáéóíúÁÉÍÓÚÑñ€:;_%$#@|!"·​()çÇüàèìòùÀÈÌÒÙäëïöüÄËÏÖÜ\-,.=\+'&\'0-9\ \n()“”"]*)/ig;
    var regexStyle3 = /<xml>[a-zA-Z0-9\n\t\r\ \<\>:\-\!\[\]=\"&#;'*\.{}@,\/]*<\/xml>/ig;
    var regexStyle4 = /<style>[a-zA-Z0-9\n\t\r\ \<\>:\-\!\[\]=\"&#;'\*\.{}@,\/%]*<\/style>/ig;
    var regexStyle5 = /<w:[a-zA-Z ]*>/ig;
    //quitamos el style
    if(text.indexOf("<style>")!==-1){
        text = text.substring(0,text.indexOf("<style>"))+""+text.substring(text.indexOf("</style>")+8);
    }
    text = text.replace(/\\"/ig,"\"");

    text = text.replace(/&amp\;/ig,"&");
    text = text.replace(/(<o:p><\/o:p>)/ig,"");
    // text = text.replace(/(<o:p>(.*)<\/o:p>)/ig,"");
    text = text.replace(/<(.):(.*)>/ig,"");
    text = text.replace(/<\/(.):(.*)>/ig,"");
    text = text.replace(/<!--(.*)-->/ig,"");
    text = text.replace(/<!(.*)-->/ig,"");
    text = text.replace(/<!--(.*)>/ig,"");
    text = text.replace( /\n/ig," ");
    text = text.replace( /\t/ig," ");
    text = text.replace(/<pclass/gi, "<p class");
    text = text.replace(/<\/pclass=\"[a-zA-Z0-9]*\">/gi, "</p>");

    text = text.replace(regexStyle,"");
    text = text.replace(regexStyle2,"");
    text = text.replace(regexStyle3,"");
    text = text.replace(regexStyle4,"");
    text = text.replace(regexStyle5,"");
    text = text.replace(/<(.):(.*)(\n(.*))*\/>/ig,"");
    text = text.replace(/<\/xml>/gi,"");
    text = text.trim();

    text = text.replace(/<table\s*>/gi, "");
    text = text.replace(/<\/table>/gi, "");
    text = text.replace(/<tbody\s*>/gi, "");
    text = text.replace(/<\/tbody>/gi, "");
    text = text.replace(/<tr\s*>/gi, "");
    text = text.replace(/<\/tr>/gi, "");
    text = text.replace(/<td\s*>/gi, "");
    text = text.replace(/<\/td>/gi, "");
    text = text.replace(/<h1\s*>/gi, "");
    text = text.replace(/<\/h1>/gi, "");
    text = text.replace(/<h2\s*>/gi, "");
    text = text.replace(/<\/h2>/gi, "");
    text = text.replace(/<h3\s*>/gi, "");
    text = text.replace(/<\/h3>/gi, "");
    text = text.replace(/<h4\s*>/gi, "");
    text = text.replace(/<\/h4>/gi, "");
    text = text.replace(/<label\s*>/gi, "");
    text = text.replace(/<\/label>/gi, "");
    text = text.replace(/<a\s*>/gi, "");
    text = text.replace(/<\/a>/gi, "");
    text = text.replace(/<font\s*>/gi, "");
    text = text.replace(/<\/font>/gi, "");
    text = text.replace(/”/gi, "\"");
    text = text.replace(/“/gi, "\"");
    text = text.replace(/–/gi, "\-");
    text = text.replace(/\[/gi, "");
    text = text.replace(/\]/gi, "");
    text = text.replace(/mailto:/gi, "");
    text = text.replace(/<em\s*>/gi, "");
    text = text.replace(/<\/em>/gi, "");
    text = text.replace(/<b\s*>/gi, "");
    text = text.replace(/<\/b>/gi, "");
    text = text.replace(/<div><br><\/div>/gi,"");
    text = text.replace(/<div>/gi,"<p>");
    text = text.replace(/<div >/gi,"<p>");
    text = text.replace(/<\/div>/gi,"</p>");
    text = text.replace(/<span\s*>/gi,"");
    text = text.replace(/<\/span>/gi,"");

    text = text.replace(/<sup\s*>/gi,"");
    text = text.replace(/<\/sup>/gi,"");
    text = text.replace(/<pre\s*>/gi,"");
    text = text.replace(/<\/pre>/gi,"");

    if(text.lastIndexOf("<br>") === text.length -4 && text.lastIndexOf("<br>")!== -1){
        //si hay un intro al final lo eliminamos
        text = text.substring(0,text.length -4);
    }
    if(text.indexOf("<br>") === 0){
        //si hay un intro al inicio lo eliminamos
        text = text.substring(4);
    }
    text = text.replace(/<br>/gi,"</p><p>");
    text = text.replace(/<\/br>/gi,"</p><p>");

    text = text.replace(/<p\s*>&nbsp;<\/p>/gi,"");
    text = text.replace(/&nbsp;/gi," ");
    text = text.replace(/<p\s*><\/p>/gi, "");
    text = text.replace( /\ +/ig," ");
    text = text.replace( /\ +</ig,"<");

    var auxFirstText = "";
    var auxLastText = "";
    if(text.indexOf("<p")===-1 && text.indexOf("</p") === -1){
        text = "<p>"+text+"</p>";
    }
    if(!(text.indexOf("<p")<2) || text.indexOf("<p")===-1) {
        auxFirstText = text.substring(0,text.indexOf("<p"));
        auxLastText = text.substring(text.indexOf("<p"));
        text = "<p>"+auxFirstText+"</p>"+auxLastText;
    }
    if(text.lastIndexOf("</p>")< text.length-4 || text.indexOf("</p")===-1) {
        auxFirstText = text.substring(0,text.lastIndexOf("</p>")+4);
        auxLastText = text.substring(text.lastIndexOf("</p>")+4);
        text = auxFirstText+"<p>"+auxLastText+"</p>";
    }

    text = text.replace(/<\/p><\/p>/gi,"</p>");
    text = text.replace(/<p><p>/gi,"<p>");
    text = text.replace(/<p><\/p>/gi,"");
    text = text.replace(/<a[^>]*>/gi, "");
    text = text.replace(/<\/a>/gi, "");
    text = text.replace(/<i>/gi, "");
    text = text.replace(/<\/i>/gi, "");

    text = parseParagraphs(text);
    return text;
};

function parseParagraphs(text){
    var aux =[];

    //This variable is a safe for an infinite loop that can happen sometimes
    var hasRunFor = 0;
    while (text.length > 0 && hasRunFor<1000){
        if(text.indexOf("</p>") === 0){
            text = text.substring(4);
        }
        if(text.indexOf("<p")!==0) {
            //hay texto vacio al inicio
            var initText = text.substring(0,text.indexOf("<p"));
            var lastText = text.substring(text.indexOf("<p"));
            text = "<p>"+initText+"</p>"+lastText;
        }  else if(text.indexOf("<p")===-1){
            text = "<p>"+text+"</p>";
        }
        //comienza por p
        var slice = text.substring(text.indexOf("<p"),text.indexOf("</p>")+4);
        var mod = 0;
        if(slice.lastIndexOf("<p>")!== -1 && slice.lastIndexOf("<p>")!== 0){
            //contiene otro párrafo que no se ha cerrado correctamente
            var initP = slice.substring(0,3);//<p> inicial
            var subSlice = slice.substring(3);//el resto
            var content = subSlice.substring(0,subSlice.indexOf("<p>"));
            slice = initP + content + "</p>";
            mod = 4;
        }
        aux.push(slice);
        text = text.substring(slice.length-mod);
        hasRunFor++;
    }
    if(hasRunFor ==1000){
        return -1;
    }
    text = aux.join("");
    return text;
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  * as utils from 'src/app/globals/utils';
import { from, BehaviorSubject } from 'rxjs';
import { toHttpParams, prepareFilters, formatUrl, checkChangesInEntity } from '../globals/utils';
import { DatePipe } from '@angular/common';
import { PopUpService } from './pop-up.service';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';
import { TokenService } from './token.service';
import { models } from '../globals/model-names';
import { EOHttpClient } from './eohttp-client.service';

declare var $;

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  baseURL:string;

  logEvents$:BehaviorSubject<any[]> = new BehaviorSubject(null);

  postHeaders:HttpHeaders = new HttpHeaders({'Content-type' : 'application/x-www-form-urlencoded'});

  constructor(private http:HttpClient, private tokenService:TokenService, private datePipe:DatePipe,
    private popUpService:PopUpService, private router:Router, private utilsService:UtilsService,
    private eoHttp:EOHttpClient) {
    this.prepareLogEvents()
    this.baseURL = '/api/'+ models.PARTNER;
  }

  sendWelcomeEmail(partnerId){
    return this.eoHttp.post(`${this.baseURL}/${partnerId}/welcome_mail`, {});
  }

  getById(partnerId){
    return this.eoHttp.get(`${this.baseURL}/${partnerId}`);
  }

  getSpecialtiesForPartner(partnerId){
    return this.eoHttp.get(`${this.baseURL}/${partnerId}/specialties`);
  }

  updateProfileBlock(block,previousText, text, idPartner){
    var currentBlock = {
        Key:block,
        Value: text
    };
    var previousBlock= {
        Key:block,
        Value: previousText
    };
    var changesObj = checkChangesInEntity(previousBlock,currentBlock);
    if(Object.keys(changesObj).length !== 0){
      return this.http.post(formatUrl('/Partner/updateProfileBlock/'+idPartner),toHttpParams(currentBlock)).toPromise();
    }else{
      return Promise.resolve({data:currentBlock});
    }
  };

  createProfileBlock(block, text, partnerId){
    var body = toHttpParams({
      Key:block,
      Value: text
    });
    return this.http.post(formatUrl('/Partner/insertProfileBlock/'+partnerId), body).toPromise();
  }

  getPageEmailTracking(email,page, limit, event){
    if(!page){page=1;}
    if(!limit){limit=25;}
    if(!event){event='';}

    //la pagina 1 es offset 0, y asi sucesivamente
    let offset = (page -1)*limit;

    let body = toHttpParams({
        offset:offset,
        limit:limit,
        event:event,
        email:email.toLowerCase()
    });

    return this.http.post(formatUrl('/SendinBlue/getLog'),body);
  };

  prepareLogEvents(){
    this.getPartnerEvents().subscribe((res:any)=>{
      this.logEvents$.next(res.data);
    });
  }

  getProfile(idp){
    return this.http.get(formatUrl('/Partner/getProfile/'+idp));
  };

  getProfileBlocks(idPartner){
    return this.http.get(formatUrl('/Partner/getProfileBlocks/'+idPartner));
  };

  getPagePartners(page, limit, fields, orderEntity, orderField, orderDirection, search, status){
    let body:any = toHttpParams(prepareFilters(page, limit, fields, orderEntity, orderField, orderDirection, search, status));

    let route = search.SpecialtyId && search.LocationFilter ? "/Partner/getAllWithOfficesInLocationLikeBySpecialty" : "/Partner/getAllWithOfficesInLocationLike" ;
    return this.http.post(utils.formatUrl(route), body, {headers:this.postHeaders});
  }

  checkPartnerExists(field,value){
    var filterToSend = [{Entity:'Partner', Field: field, Value: value}];

    var body = utils.toHttpParams({
        equalFilters: JSON.stringify(filterToSend)
    });
    return this.http.post(utils.formatUrl('/Partner/exists'),body);
  };

  getAllProfessionalAssociations(){
    return this.http.get(utils.formatUrl('/ProfessionalAssociation/getAll'));
  };

  getLeadPartners(id){
    return this.http.get(utils.formatUrl('/Partner/getAllAllowedByLead/'+id));
  };

  getPartnersWorkingRemotely(leadId){
    return this.http.get(utils.formatUrl('/Partner/getAllEligibleByLeadWorkingRemotely/'+leadId));
  }

  getPartnersByLeadLocation(leadId, distance = 30, minimumMood = 1, maximumMood = 5){
    let body = utils.toHttpParams({Distance: distance, MinimumMood : minimumMood, MaximumMood: maximumMood});
    return this.http.post(utils.formatUrl('/Partner/getAllEligibleByLead/'+leadId), body);
  }

  getPartnersByLeadLocationZone(leadId){
    return this.http.get(utils.formatUrl('/Partner/getAllEligibleByLead2/'+leadId));
  }

  getPartnersByOrganization(organization){
    return this.http.get(utils.formatUrl('/Partner/getAllByOrganization/'+organization.Id));
  };

  saveSpecialties(specialties, partnerId){
    let body = $.param({
        PartnerId: partnerId,
        SpecialtyId: specialties
    });
    return this.http.post(utils.formatUrl('/Partner/addSpecialties'), body, {headers:this.postHeaders});
  };

  deleteSpecialties(specialties, partnerId){
    let body = $.param({
      PartnerId: partnerId,
      SpecialtyId: specialties
    });
    return this.http.post(utils.formatUrl('/Partner/removeSpecialties'), body, {headers:this.postHeaders});
  };

  manageMarketingEmails(partner){
    if (partner.WantsToReceiveEmails === '0') {
      partner.WantsToReceiveMarketing = '0';
      partner.EmailSettingId = 5;//si no esta marcado el WantsToReceiveEmails, seteamos a No emails.
      partner.TrashcanEmailSettingId = 5;//si no esta marcado el WantsToReceiveEmails, seteamos a No emails.
    }
  }

  manageSpecialties(partnerId, specialtiesSelected, specialtiesBefore){

    let specialtiesIds = [];
    let specialtiesBeforeIds = [];

    if(specialtiesSelected.length>0 && typeof specialtiesSelected[0] != 'string'){
      specialtiesIds = specialtiesSelected.map(specialty=>specialty.Id);
    }
    if(specialtiesBefore.length>0 && typeof specialtiesBefore[0] != 'string'){
      specialtiesBeforeIds = specialtiesBefore.map(specialty=>specialty.Id);
    }
    let specialtiesToAdd = specialtiesIds.filter(specialty=>specialtiesBeforeIds.indexOf(specialty)==-1)
    let specialtiesToRemove = specialtiesBeforeIds.filter(specialty=>specialtiesIds.indexOf(specialty)==-1)

    let promises = [];
    if(specialtiesToAdd.length>0){
      promises.push(this.saveSpecialties(specialtiesToAdd, partnerId).toPromise());
    }
    if(specialtiesToRemove.length>0){
      promises.push(this.deleteSpecialties(specialtiesToRemove, partnerId).toPromise())
    }
    if(promises.length==0){
      return Promise.resolve(true);
    }
    return Promise.all(promises);
  }

  savePartner(partner, partnerBefore){
    this.manageMarketingEmails(partner);
    var partnerToUpdate:any = utils.checkChangesInEntity(partnerBefore,partner);

    if(Object.keys(partnerToUpdate).length === 1){
      return from(Promise.resolve({data:partner}));
    }else{
      partnerToUpdate.ChangeByAdminId = this.tokenService.decoded.id;
      let body = utils.toHttpParams(partnerToUpdate);
      return this.http.post(utils.formatUrl('/Partner/update/'+partner.Id), body);
    }
  }

  insertPartner(partner){
    partner.LoggedAdminId = this.tokenService.decoded.id;
    this.manageMarketingEmails(partner);

    let body = utils.toHttpParams(partner);

    return this.http.post(utils.formatUrl('/Partner/insert'),body);
  };

  deletePartner(id){
    return this.http.get(utils.formatUrl('/Partner/delete/'+id));
  };

  addPartnerToLead(idPartner, idLead){
    var data = null;
    if(Array.isArray(idPartner)){
        data = {
            PartnerId : idPartner,
            LeadId: idLead
        };
    }else{
        data = {
            PartnerId : [idPartner],
            LeadId: idLead
        };
    }
    data.AdminId = this.tokenService.decoded.id;
    let body = $.param(data);
    return this.http.post(utils.formatUrl('/Partner/addPartnersToLead'), body, {headers: this.postHeaders})
  };

  recoverPartner(partnerId){
    let now = new Date();
    let newFreeTrialDate = this.datePipe.transform(new Date(now.getTime() + 30*24*3600*1000));
    this.popUpService.showLoading();
    this.savePartner({Id: partnerId, IsDeleted: 0, FreeTrialExpirationDate: newFreeTrialDate, FreeTrialExpired: 0},{}).subscribe((res:any)=>{
      if(res && res.data){
        this.popUpService.hideLoading();
        this.router.navigateByUrl('/partners/edit/'+partnerId);
      }else{
        this.utilsService.manageError({type:"warning",message:"The partner does not exist and could not be recovered"});
      }
    },(err)=>{this.utilsService.manageError({message: "Error recovering the partner", err});});
  };

  deletePartnerFromLead(idPartner, idLead){
    var data = null;
    if(Array.isArray(idPartner)){
      data = {
        PartnerId : idPartner,
        LeadId: idLead
      };
    }else{
      data = {
        PartnerId : [idPartner],
        LeadId: idLead
      };
    }
    data.AdminId = this.tokenService.decoded.id;
    let body = $.param(data);
    return this.http.post(utils.formatUrl('/Partner/removePartnersFromLead'), body, {headers: this.postHeaders})
  };

  getPagePartnerActivities(page, limit, fields,orderEntity, orderField, orderDirection,search,status,partnerId){
    let body:any = toHttpParams(prepareFilters(page, limit, fields, orderEntity, orderField, orderDirection, search, status));
    return this.http.post(utils.formatUrl('/PartnerLog/getAllByPartner/'+partnerId),body);
  };

  getPagePartnerActivitiesByAdmin(page, limit,fields, orderEntity, orderField, orderDirection, search, adminId){
    let order=[{'Entity':orderEntity,'Field':orderField,'Direction':orderDirection}];
    let lessThanFilters = [];
    let greaterThanFilters = [];
    let body:any = utils.toHttpParams({
      offset: page * limit,
      limit:limit,
      orderBy: JSON.stringify(order),
      getTotal:1,
      equalFilters: JSON.stringify(search.equalFilters),
      likeFilters: JSON.stringify(search.likeFilters),
      lessThanFilters: JSON.stringify(lessThanFilters),
      moreThanFilters: JSON.stringify(greaterThanFilters),
      nullFilters: JSON.stringify(search.nullFilters),
      notNullFilters: JSON.stringify(search.notNullFilters),
      FieldsToReturn:JSON.stringify(fields),
    });

    return this.http.post(utils.formatUrl('/PartnerLog/getAllByAdmin/'+adminId), body);
  };

  getPagePartnerAllActivities(page, limit, fields,orderEntity, orderField, orderDirection, search){
    let order=[{'Entity':orderEntity,'Field':orderField,'Direction':orderDirection}];
    let lessThanFilters = [];
    let greaterThanFilters = [];
    let body:any = utils.toHttpParams({
      offset: page * limit,
      limit:limit,
      orderBy: JSON.stringify(order),
      getTotal:1,
      equalFilters: JSON.stringify(search.equalFilters),
      likeFilters: JSON.stringify(search.likeFilters),
      lessThanFilters: JSON.stringify(lessThanFilters),
      moreThanFilters: JSON.stringify(greaterThanFilters),
      nullFilters: JSON.stringify(search.nullFilters),
      notNullFilters: JSON.stringify(search.notNullFilters),
      FieldsToReturn:JSON.stringify(fields),
    });

    return this.http.post(utils.formatUrl('/PartnerLog/getAll'), body);
  };

  getPageProfiles(page, limit, fields, orderEntity, orderField, orderDirection, search){
    let order=[{'Entity':orderEntity,'Field':orderField,'Direction':orderDirection}];
    let lessThanFilters = [];
    let greaterThanFilters = [];
    let body:any = utils.toHttpParams({
      offset: page * limit,
      limit:limit,
      orderBy: JSON.stringify(order),
      getTotal:1,
      equalFilters: JSON.stringify(search.equalFilters),
      likeFilters: JSON.stringify(search.likeFilters),
      lessThanFilters: JSON.stringify(lessThanFilters),
      moreThanFilters: JSON.stringify(greaterThanFilters),
      nullFilters: JSON.stringify(search.nullFilters),
      notNullFilters: JSON.stringify(search.notNullFilters),
      FieldsToReturn:JSON.stringify(fields),
    });
    return this.http.post(utils.formatUrl('/Partner/getAllProfiles'),body);
  };

  getPartnerEvents(){
    return this.http.get(utils.formatUrl('/PartnerLog/getAllEvents'));
  };

  getAllEmailNotifications(partnerId){
    return this.http.get(utils.formatUrl('/Partner/getEmailSettingsAvailable/'+partnerId));
  };

  getPartnerLogByLead(leadId){
    return this.http.get(utils.formatUrl('/PartnerLog/getAllByLead/'+leadId));
  }

  get(id){
    return this.http.get(utils.formatUrl('/Partner/get/'+id));  
  }

  regeneratePassword(idPartner){
    return this.http.get(utils.formatUrl('/Partner/sendNewRandomPasswordToPartner/'+idPartner));
  };

  changePartnerPassword(password, partnerId){
    var body =utils.toHttpParams({
        PlainPassword : password
    });
    return this.http.post(utils.formatUrl('/Partner/assignNewPassword/'+partnerId), body);
  };

}

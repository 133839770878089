import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrimInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>> {
    let queryParams:HttpParams = req.params;
    let newParams:HttpParams = new HttpParams();
    queryParams.keys().map(key=>{
      let value = queryParams.get(key);
      if(typeof value == 'string'){
        newParams = newParams.append(key, value.trim());
      }else{
        newParams = newParams.append(key, value);
      }
    });
    req = req.clone({params: newParams});
    if(!req.body || typeof req.body != 'object'){
      return next.handle(req);
    }
    let body = Object.assign({},req.body);
    Object.entries(body).map(([key,value]:any)=>{
      if(typeof body[key] == 'string'){
        body[key] = value.trim();
      }
    })
    req = req.clone({body});
    return next.handle(req);
  }

}
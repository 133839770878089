import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'specialties'
})
export class SpecialtiesPipe implements PipeTransform {

  transform(specialtyArray: any, newAPI?:boolean): any {
    if(!newAPI){
      return this.oldAPITransform(specialtyArray);
    }
    else{
      return this.newAPITransform(specialtyArray);
    }
  }

  newAPITransform(specialtyArray){
    if(!specialtyArray){
      return '';
    }
    let names = specialtyArray.map(el=>el.name);
    let retValue = names.join(', ');

    return retValue;
  }

  oldAPITransform(specialtyArray){
    if(!specialtyArray){
      return '';
    }
    let names = specialtyArray.map(el=>el.Name);
    let retValue = names.join(', ');

    return retValue;
  }
}

import { Injectable } from '@angular/core';
import { EOHttpClient } from './eohttp-client.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private eoHttp:EOHttpClient, private utilsService:UtilsService) { }

  get(IRI, extraParams = {}){
    return this.eoHttp.get(IRI, extraParams);
  }

  put(entity){
    let auxEntity = this.utilsService.reduceIRIs(entity);
    return this.eoHttp.put(entity['@id'], auxEntity);
  }

  post(entity, model){
    let auxEntity = this.utilsService.reduceIRIs(entity);
    return this.eoHttp.post('/api/'+model, auxEntity);
  }

  delete(entity){
    return this.eoHttp.delete(entity['@id']);
  }

}

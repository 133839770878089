import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFromToday'
})
export class TimeFromTodayPipe implements PipeTransform {

  transform(value: any, type?:string): any {
    const today = new Date();
    let checkDate = new Date(value);
    if(type=='deadline'){
      checkDate.setDate(checkDate.getDate()+30);
    }
    let diff;
    if(type=='deadline'){
      diff = (today.getTime() - checkDate.getTime()) / ( 1000 * 3600)*-1;
    }
    else{
      diff = (today.getTime() - checkDate.getTime()) / ( 1000 * 3600);
    }
    let isNegative = diff<0 ? -1 : 1;
    let abs = Math.abs(diff);
    if(abs < 24){
      return (Math.floor(abs)*isNegative) + "h";
    } 
    else{
      return (Math.floor(abs/24)*isNegative) + "d";
    }
  }

}

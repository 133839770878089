import { Injectable } from '@angular/core';
import { EOHttpClient } from './eohttp-client.service';

@Injectable({
  providedIn: 'root'
})
export class LeadReceivedService {

  constructor(private eoHttp:EOHttpClient) { }

  getById(leadId){
    return this.eoHttp.get('/api/leads_received/'+leadId);
  }

  save(lead){
    return this.eoHttp.put(lead['@id'],lead);
  }

  insert(lead){
    return this.eoHttp.post('/api/leads_received',lead);
  }

  cancelValidation(leadId){
    return this.eoHttp.post('/api/leads_received/cancel_validation/'+leadId, {});
  }

  validate(leadId, notifyAt?){
    let body:any = {}
    if(notifyAt) body.notify_at = notifyAt;
    return this.eoHttp.post('/api/leads_received/validate/'+leadId,body);
  }

  prevalidate(leadId){
    return this.eoHttp.post('/api/leads_received/prevalidate/'+leadId,{});
  }

  revalidate(leadId, silent){
    return this.eoHttp.put('/api/leads_received/revalidate/'+leadId,{silent});
  }

  close(leadId){
    return this.eoHttp.put('/api/leads_received/close/'+leadId,{});
  }

  reopen(leadId){
    return this.eoHttp.put('/api/leads_received/reopen/'+leadId,{});
  }

  reject(leadId, rejectionReason, duplicatedId?){
    let body:any = {rejectionId : rejectionReason};
    if(duplicatedId) body.duplicatedFromId = duplicatedId;
    return this.eoHttp.post('/api/leads_received/reject/'+leadId, body);
  }

  lock(leadId){
    return this.eoHttp.put('/api/leads_received/lock/'+leadId,{});
  }

  scheduleContact(lead){
    return this.eoHttp.post('/api/leads_received/schedule_contact/'+lead.id,{contact_at : lead.contactScheduledAt});
  }

  unlock(leadId){
    return this.eoHttp.put('/api/leads_received/unlock/'+leadId,{});
  }

  getDuplicates(emailOrPhone){
    return this.eoHttp.get('/api/leads_received/find_duplicates',{emailOrPhone});
  }

  getNext(){
    return this.eoHttp.post('/api/leads_received/lock_next_available',{});
  }

  getMeta(leadId){
    return this.eoHttp.get('/api/leads_received/'+leadId+'/meta');
  }

  getContactAttempts(leadId){
    return this.eoHttp.get('/api/leads_received/'+leadId+'/contact_actions');
  }

  getAdminActions(leadId){
    return this.eoHttp.get('/api/leads_received/'+leadId+'/lead_actions');
  }

  getReceivedLeads(page, pageSize, extraParams:any={}, sortField?, sortOrder?){
    extraParams = JSON.parse(JSON.stringify(extraParams));
    extraParams.page = page+1;
    extraParams.pageSize = pageSize;
    if(sortField && sortOrder){
      extraParams["_order["+sortField+"]"] = sortOrder;
    }
    return this.eoHttp.get('/api/leads_received',extraParams);
  }

}

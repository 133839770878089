import { Injectable } from '@angular/core';
import { CACHE_ENDPOINTS } from '../globals/constants';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class EOHttpClient {

  constructor(private tokenService:TokenService, private http:HttpClient) {}

  getAuthHeader(){
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.tokenService.token);
    return headers;
  }

  get(url, params?:any){
    if(CACHE_ENDPOINTS.includes(url)){
      let data:any = JSON.parse(localStorage.getItem(url));
      if(data){
        let expirationDate = new Date(data.expiration);
        let now = new Date();
        if(expirationDate > now){
          return of(data.value);
        }
      }
    }
    let options:any = {headers: this.getAuthHeader()};
    let queryParams = new HttpParams();
    if(params){
      Object.entries(params).map(([key, value]:any)=>{
        if(Array.isArray(value)){
          value.map(elem=>{
            queryParams = queryParams.append(key,elem);
          })
        }else{
          queryParams = queryParams.append(key, value);
        }
      });
    }
    /* We can not pass queryparams as an option because it removes duplicate keys that we use for array params */
    return this.http.get( window["BASE_URL_CORE19"] + url + '?' + queryParams.toString(), options);
  }

  put(url, data){
    return this.http.put(window["BASE_URL_CORE19"] + url, data, {
      headers: this.getAuthHeader()
    });
  }

  post(url, data){
    return this.http.post(window["BASE_URL_CORE19"] + url, data, {
      headers: this.getAuthHeader()
    });
  }

  delete(url){
    return this.http.delete(window["BASE_URL_CORE19"] + url, {
      headers: this.getAuthHeader()
    });
  }

}

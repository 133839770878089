import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { PagesComponent } from './components/pages/pages.component';
import { AppComponent } from './app.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: '',
    component: AppComponent,
    loadChildren: './modules/login/login.module#LoginModule'
  },
  { path: 'partners', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/partners/partners.module#PartnersModule',
  },
  { path: 'organizations', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/organizations/organizations.module#OrganizationsModule',
  },
  { path: 'specialties', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/specialties/specialties.module#SpecialtiesModule',
  },
  { path: 'locations', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/locations/locations.module#LocationsModule',
  },
  { path: 'reporting', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/reporting/reporting.module#ReportingModule',
  },
  { path: 'bids', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/bids/bids.module#BidsModule',
  },
  { path: 'boost-leads', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/boost-leads/boost-leads.module#BoostLeadsModule',
  },
  { path: 'admin-panel', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/admin-panel/admin-panel.module#AdminPanelModule',
  },
  { path: 'leads', 
    component: PagesComponent, 
    canActivate: [ LoginGuard ],
    loadChildren: './modules/leads/leads.module#LeadsModule',
  },
  {
    path: '',
    component: PagesComponent,
    canActivate: [ LoginGuard ],
    loadChildren: './modules/pages/pages.module#PagesModule'
  },
];

const extraoptions: ExtraOptions = {onSameUrlNavigation: 'reload'}

@NgModule({
  imports: [RouterModule.forRoot(routes, extraoptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

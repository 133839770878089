import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partnerFilter'
})
export class PartnerFilterPipe implements PipeTransform {

  transform(value: any, withCredits:any = false): any {
    if(!withCredits){
      return value.filter(partner => partner.selected).length;
    }
    else{
      return value.filter(partner => partner.selected && partner.Credits!=null && partner.Credits > 0).length;
    }
  }

}

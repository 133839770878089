import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PopUpService } from '../../services/pop-up.service';
import { PartnerService } from 'src/app/services/partner.service';
import { slideUpDown } from 'src/app/animations/animations';
import { menuitems } from './sidebar-map';
import { environment } from 'src/environments/environment';
import { HasCredentialPipe } from '../../pipes/has-credential.pipe';
import { LeadReceivedService } from 'src/app/services/lead-received.service';
import { UtilsService } from 'src/app/services/utils.service';
import { TokenService } from 'src/app/services/token.service';
import { AuthService } from 'src/app/services/auth.service';
import { CrudService } from 'src/app/services/crud.service';

@Component({
  selector: 'app-sidebar',
  animations: [
    slideUpDown
  ],
  providers: [ HasCredentialPipe ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  idToSearch:string = '';
  loadingLead:boolean = false;
  loadingPartner:boolean = false;
  loadingKODAK:boolean = false;
  onLeadId:any;
  oldPassword:string;
  newPassword:string;
  newPassword2:string;
  selectedSection:string = 'organizations';
  menuItems:any = menuitems;

  VERSION:string = environment.VERSION;

  @ViewChild('noKodakLeads', { static: true }) noKodakSwal:SwalComponent;
  @ViewChild('changePasswordSwal', { static: true }) changePasswordSwal:SwalComponent;

  constructor(private partnerService:PartnerService, private leadReceivedService:LeadReceivedService,
    private router:Router, public tokenService:TokenService, private utilsService:UtilsService,
    private popUpService:PopUpService, private hasCredentialPipe:HasCredentialPipe,
    private authService:AuthService, private crudService:CrudService) { 
    }

  ngOnInit() {
    this.processAllowedRoutes();
    this.getSelectedSection();
  }

  getSelectedSection(){
    for(let item of this.menuItems){
      if(item.children){
        let child = item.children.find(child=>{
          let entityList = '/'+this.router.url.split('/')[1]+'/list';
          return (child.url==this.router.url || child.url == entityList);
        });
        if(child){
          this.selectedSection = item.label;
          return;
        }
      }else{
        if(item.label==this.router.url){
          this.selectedSection = item.label;
          return;
        }
      }
    }
  }

  processAllowedRoutes(){
    this.menuItems.map(item=>{
      if(item.children){
        let results = item.children.map(child=>child.allowed = this.isItemAllowed(child));
        item.allowed = results.includes(true);
      }
      else{
        item.allowed = this.isItemAllowed(item);
      }
    });
  }

  isItemAllowed(item){
    if(!item.credentials){
      return true;
    }
    return item.credentials.filter(value=>this.hasCredentialPipe.transform(value)).length>0;
  }

  onSectionClick(item){
    if(item.children){
      this.selectedSection == item.label ? this.selectedSection = null : this.selectedSection = item.label;
    }
    else{
      if(item.label=='KODAK'){
        this.kodak();
      }
    }
  }

  kodak(){
    if(this.loadingKODAK){
      return;
    }
    this.loadingKODAK = true;
    this.leadReceivedService.getNext().subscribe((res:any)=>{
      console.log("Kodak res:",res);
      if(res){
        this.router.navigateByUrl('/leads/edit/'+res.id);
      }else{
        this.popUpService.swal("Info", "No more leads to validate", "info");
      }
      this.loadingKODAK = false;
    },()=>{
      this.popUpService.swal("Error", "Error getting the next lead to validate", "error");
      this.loadingKODAK = false;
    })
  }

  checkCanChangePasswordButton(){
    if(this.oldPassword && this.newPassword && this.newPassword2){
      this.changePasswordSwal.nativeSwal.enableConfirmButton();
    }
    else{
      this.changePasswordSwal.nativeSwal.disableConfirmButton();
    }
  }

  changePassword(){
    if(this.newPassword != this.newPassword2){
      this.popUpService.swal("Warning", "The passwords do not match", "warning").then(()=>{
        this.changePasswordSwal.show();
      });
      return false;
    }
    else{
      return new Promise((resolve)=>{
        this.authService.changePassword(this.oldPassword, this.newPassword, this.newPassword2).subscribe((res:any)=>{
          if(res.message){
            this.popUpService.swal("Warning",res.message,"warning");
          }else{
            this.popUpService.swal("Success","Password changed","success");
            this.newPassword = this.newPassword2 = this.oldPassword = null;
            this.authService.saveUser(res);
          }
        },err=>this.utilsService.manageError({message:"Error changing the password", err}));
      });
    }
  }

  searchLead(){
    if(this.idToSearch){
      this.loadingLead=true;
      this.leadReceivedService.getById(this.idToSearch.trim()).subscribe((res:any)=>{
        console.log("Res:",res);
        this.router.navigateByUrl('/leads/edit/'+res.id);
        this.loadingLead=false;
      },err=>{
        if(err.status=='404'){
          this.popUpService.swal("Warning", "Lead with Id "+this.idToSearch +" not found","warning");
        }else{
          this.utilsService.manageError({message:"Error searching for the lead", err});
        }
        this.loadingLead=false;
      });
    }
  }
  searchPartner(){
    if(this.idToSearch){
      this.loadingPartner=true;
      this.partnerService.getById(this.idToSearch.trim()).subscribe((partner:any)=>{
        this.loadingPartner=false;
        if(partner.deleted){
          this.popUpService.swalWithOptions({title:"Warning", text:"The partner with Id " + this.idToSearch.trim() + " is deleted", type:"warning",
          cancelButtonText: "Recover partner", showCancelButton:true, cancelButtonColor:"#dc3545"}).then(
            isConfirm=>{
              if(isConfirm.dismiss=='cancel'){
                partner.deleted=false;
                this.crudService.put(partner).subscribe(res=>{
                  this.router.navigateByUrl('/partners/edit/'+partner.id);
                })
            }
          });
        }else{
          this.router.navigateByUrl('/partners/edit/'+partner.id);
        }
      },err=>{
        this.loadingPartner=false;
        this.popUpService.swal("Warning","The partner with Id "+this.idToSearch.trim() + " does not exist",'warning');
      })
    }
  }

  logOut(){
    this.authService.logOut();
  }

}
